import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom'
import { MdLocationOn } from "react-icons/md";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';

import bannerTo from '../../../images/banner-resize-8.jpeg';
import bannerToBottom from '../../../images/banner-resize-9.jpeg';
import SearchInputForModal from './SearchInputForModal';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';


const SearchWithAutoComplete = (props) => {

    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const history = useHistory()
    const location = useLocation();
    const pathname = location.pathname;
    const pathnameSearch = location.search;

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    // Fonction pour effectuer la recherche
    const handleSearch = (event) => {
        const { value } = event.target;
        setSearchValue(value);
        props.searchNameWithCallback(value);
    };

    const checkIfModalIsOpenOrNot = () => {
        setOpen(true);
    };

    const goToDetailsPage = (option) => {
        // let newPathname = pathname;
        if (pathname.includes('/categories')) {
            let newPathname = pathname.replace(/\/categories/g, '');

            let urlPath = newPathname.includes("?") ? "/product" + option.url_slug + "&slug=" + option.slug : "/product" + option.url_slug + "?slug=" + option.slug
            let urlPathForAPI = option.url_slug + props.includesForAllProducts_t + "&slug=" + option.slug

            props.allProductsBycategories(urlPathForAPI)

            history.push(urlPath);
            setOpen(false)
            // console.log("newPathname: " + newPathname); return;
        } if (pathname.includes('/product')) {
            let newPathname = pathname.replace(/\/product/g, '');

            let urlPath = newPathname.includes("?") ? "/product" + option.url_slug + "&slug=" + option.slug : "/product" + option.url_slug + "?slug=" + option.slug
            let urlPathForAPI = option.url_slug + props.includesForAllProducts_t + "&slug=" + option.slug

            props.allProductsBycategories(urlPathForAPI)

            history.push(urlPath);
            setOpen(false)

            // console.log("newPathname: " + newPathname); return;
        } else {
            let urlPath = "/product" + option.url_slug + "?slug=" + option.slug;
            history.push(urlPath);
            setOpen(false)
        }
        // else {
        //     let newPathname = pathname;
        //     let urlPath = newPathname.includes("?") ? "/product" + newPathname + "&slug=" + option.slug : "/product" + newPathname + "?slug=" + option.slug
        //     let urlPathForAPI = newPathname + props.includesForAllProducts_t + "&slug=" + option.slug


        //     props.allProductsBycategories(urlPathForAPI)

        //     history.push(urlPath);
        // }



        // history.push(`/product/${id}`)
    };

    // let CatOrSpecialistObject = useSelector((state) => state.settings.__catname);

    const [isOpen, setIsOpen] = useState(false);

    const renderFriend = (props, option) => {

        return (
            // <div {...props} onClick={(v) => goToDetailsPage(option.value)}
            <div {...props} onClick={(v) => goToDetailsPage(option)}
                style={{ 'font-size': '16px' }}>
                <img
                    src={option.image}
                    alt={option.name}
                    className='imgSearchRender_t'
                />
                {option.name}
            </div>
        );

    }



    return (
        <>
            <div>

                <SearchInputForModal
                    className_div_2={props.className_div_2}
                    input_className={props.input_className}
                    input_id={props.input_id}
                    input_placeholder={props.input_placeholder}
                    input_type={props.input_type}
                    name={props.name}
                    images_className={props.images_className}
                    gotToParentValue={props.gotToParentValue}
                    // searchR_t={props.searchR_t}
                    checkIfModalIsOpenOrNot={checkIfModalIsOpenOrNot}
                />

                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="search-modal"
                    aria-describedby="search-modal-description"
                >
                    <div className='divModalsearch_t'>

                        <Autocomplete
                            ref={inputRef}
                            freeSolo
                            id="foodSearch_t"
                            disableClearable
                            // value={CatOrSpecialistObject !== undefined ? CatOrSpecialistObject : specialistName}
                            loading={props.loadingAutoC}
                            options={props.options}
                            getOptionLabel={(option) => option.name}
                            open={isOpen} // Utilisez l'état de la liste déroulante pour contrôler son ouverture
                            onOpen={() => setIsOpen(true)} // Définir l'état comme ouvert
                            onClose={() => setIsOpen(false)}
                            renderInput={(params) => (
                                <TextField {...params}
                                    className='inputModalsearch_t'
                                    // helperText="Please enter your name"
                                    onChange={handleSearch}
                                    value={searchValue}
                                    placeholder="Recherche ton produit"
                                    // label=""
                                    variant="outlined"
                                    InputLabelProps={{ shrink: false }}
                                    InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                        sx: {
                                            fontSize: '19px',
                                            marginTop: '25px'
                                        }
                                    }} />
                            )}
                            renderOption={renderFriend}
                        />
                        <IconButton
                            className="searchIconForSearchModalClass_t"
                            type="button"
                            sx={{ p: '40px 0 0 0', color: '#42a350' }}
                            aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </div>
                </Modal>
            </div>

        </>


    )
}
export default SearchWithAutoComplete;