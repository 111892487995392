import React, { Component } from 'react';
import ReeValidate from 'ree-validate';
import fr from 'ree-validate/dist/locale/fr';
import Swal from 'sweetalert2';
import en from 'ree-validate/dist/locale/en';
import classnames from 'classnames';
import { Helmet } from "react-helmet";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { withTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import PointRelaisService from '../../../services/pointRelais';
import AuthService from '../../../services/auth';
import ProductService from '../../../services/product';
import UsersService from '../../../services/users';
import CartService from '../../../services/cart';
import StripeServices from '../../../services/stripe';
import { lightTheme, darkTheme } from '../../../js/theme';
import { GlobalStyles } from '../../../js/global';
import {
  changeNavigatorTheme, auth_t, cartUpdateTab_t, deleteCartTab_t, onClickOnCmd, prodId_t, cartInitTab_t, relaypointId_t,
  initRelaypointId,
  showCarteadhesionStore, navCartStore, existantReceiverUser, saveUserInfos,
  relayPointValue, beneficiaryLocation, initRelayPointData, typeOfHomeLocalization,
  socialAssistanceObject, selectedValueFunct, economicAccessibility, seacrchListAction
} from '../../../store/actions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PanierContent from './Components/PanierContent';
import DataModal from './Components/Modals/GoToLogin'
import HeaderUsersMobile from './../../layout/HeaderUsersMobile';
import MenuCategiries from '../Home/Components/MenuCategiries';
import MobileCategoriesMenu from '../../layout/HeaderComponents/MobileCategoriesMenu';
import NewHeader from '../../layout/NewHeader5';

class Page extends Component {
  constructor(props) {
    super();
    this.validator = new ReeValidate.Validator({
      lastname: 'required',
      firstname: 'required',
      email: 'required|email',
      password: 'required|min:6',
      country: 'required',
      city: 'required',
      district: 'required',
    });
    this.validator.localize('fr', fr)
    this.state = {
      postregister: {
        lastname: '',
        firstname: '',
        email: '',
        district: '',
        phone: '',
        country_id: null,
        country: '',
        city_id: null,
        city: ''
      },
      postlogin: {
        email: '',
        password: '',
      },
      errors: this.validator.errors,
      offline: false,
      theme_bool: false,
      langBool: true,
      def_theme: '',
      eye_password_bool: true,
      checked: true,
      checked_2: false,
      checked_3: false,
      checked_switch: true,
      show_t: 'hidden_t',
      text_or_not: false,
      isLoading: false,
      first_nav_btn_t: true,
      second_nav_btn_t: false,
      dir_btn_suiv_t: false,
      show_first_tab: true,
      show_second_tab: false,
      dir_btn_prec_2_t: false,
      isValue: false,
      email_exit: false,
      showAlert: false,
      email_not_val: '',
      options_fr: [
        { value: 'en', label: "Anglais" }
      ],
      options_eng: [
        { value: 'fr', label: "Francais" }
      ],
      options_devise: [
        { value: '€', label: "Dollar" }
      ],
      option_country: [
        { value: 'cmr', label: "Cameroun" },
        { value: 'cg', label: "Congo k" }
      ],
      option_prix: [
        { value: '1000', label: "1000F" },
        { value: '2000', label: "2000F" }
      ],
      pays_value: '',
      country_value: '',
      prix_t: null,
      devise_t: 'FCFA',
      prix2_t: null,
      option_city: [
        { value: '', label: "" },
        { value: '', label: "" }
      ],
      city_id: null,
      option_sexe: [
        { value: 'Masculin', label: 'Masculin' },
        { value: 'Feminin', label: 'Feminin' }
      ],
      home_t: true,
      categorie_value: '',
      option_categorie: [
        { value: ' ', label: " " }
      ],
      pointRelaisValue: '',
      optionPointRelais_t: [
        { value: ' ', label: " " }
      ],
      dropdownBtn_t: false,
      greater_than: '',
      input1_bool_t: true,
      input2_bool_t: false,
      show_val_interv: false,
      interval_error: false,
      bool_val: false,
      index_t: null,
      products: [],
      categorieLabel: [],
      textTriePrix: '',
      showTrieBool_t: false,
      total_pages: 2,
      count: 0,
      current_page: 1,
      per_page: 2,
      count: 2,
      qtyValue_t: 1,
      qtyValueArr_t: [],
      coefAndPrice: [],
      qty_t: 0,
      showCart: true,
      showCarteadhesion: false,
      showPr: false,
      showReceveur: false,
      showPaiement: false,
      toggleBool_t: false,
      searchR_t: '',
      seacrchList: [],
      user_id: '',
      firstname: '',
      lastname: '',
      country: '',
      phone: '',
      receiverUserReal_id: null,
      isUserProf: false,
      myCart: false,
      valueRelayPoint: '',
      is_pay_by_creator: true,
      whoConstitutesCart: null,
      donatorHashId_t: '',
      nameOfWhoConstitutesCart: '',
      emailOfWhoConstitutesCart: '',
      phoneOfWhoConstitutesCart: '',
      ls_t: false,
      showBtnTrue2: false,
      showBtnTrue: false,
      modalAlertTitle: <h2>Connectez-vous !</h2>,
      sendCart: true,
      userLinks: [],
      nameChoice: '',
      userExistBool: false,
      userNotExistBool: true,
      isAuthenticated: props.isAuthenticated,
      creator_name: "",
      cartActiveBool: true,
      amountUserLink: null,
      realCard: true,
      isDeferredPayment: true,
      typeOfDeferredPayment: 0,
      isSocialAssistanceOption: false,
      membershipCardClick: false,
      rpointIsNotchoosed: false,
      selectedValue: '3%',
      tabsCatValue: 0,
      addressOfBeneficiary: '',
      allGeocodeInformationOfBeneficiary: "",
      heure: 0,
      minute: 0,
      seconde: 0,
      montantLivraison: 0,
      totalHtArrondie: 0,
      totalTauxInteretArrondie: 0,
      totalTtcArrondie: 0,
      allCategories: []
    };
    this.myRef = React.createRef();

    this.theme_botton_D = this.theme_botton_D.bind(this);
    this.theme_botton_W = this.theme_botton_W.bind(this);
    this.toggle = this.toggle.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    this.checked_t = this.checked_t.bind(this);
    this.checked_rad1_t = this.checked_rad1_t.bind(this);
    this.checked_rad2_t = this.checked_rad2_t.bind(this);
    this.checked_rad3_t = this.checked_rad3_t.bind(this);
    this.for_remove_js = this.for_remove_js.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeLogin = this.handleChangeLogin.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this)
    this.validateAndSubmitLogin = this.validateAndSubmitLogin.bind(this)
    // this.post_login_user = this.post_login_user.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.def_theme === '') {
      this.check_theme();
    }
  }
  componentDidMount() {
    this.setState({
      addressOfBeneficiary: !!this.props.distanceBeneficiarySTore && this.props.distanceBeneficiarySTore.isAtHome === "Yes" ? this.props.distanceBeneficiarySTore.adresse : this.state.addressOfBeneficiary,
    });
    this.props.dispatch(auth_t({ __iA: true, __iV: true }));
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: true });
    }
    if (d_theme === "\"dark\"") {
      this.setState({ checked_switch: false });
    }
    if (this.props.i18n.language === 'fr-FR' || 'fr') {
      this.setState({ langBool: true })
    }
    if (this.props.i18n.language === 'en') {
      this.setState({ langBool: false })
    }
    this.allCountries();
    this.allProducts();
    this.allCategories();
    // this.scrollToMyRef();
    if (this.state.isAuthenticated === true) {
      this.profile();
      this.getUserLink_t();
      this.allPointRelais();
      this.getUserDonatorsLink(this.props.prodId_t);
    }
    if (!this.props.cartBrowse) {
      this.props.dispatch(navCartStore({
        showPr: false,
        showCart: true,
        showCarteadhesion: false,
        showReceveur: false,
        showPaiement: false,
        realCard: false
      }))
    }

    this.interval = setInterval(() => {
      const dateActuelle = new Date();
      this.setState({
        heure: dateActuelle.getHours(),
        minute: dateActuelle.getMinutes(),
        seconde: dateActuelle.getSeconds()
      });
    }, 1000);

    this.findTargetUser();

  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleChangeTabsCatValue = (event, newValue) => {
    this.setState({ tabsCatValue: newValue });
    if (newValue === 0) {
      this.props.history.push("/");
    }
    if (newValue === 1) {
      this.props.history.push("/products/country:33+Cameroun/categorie:8?Alimentaires");
    }
    if (newValue === 2) {
      this.props.history.push("/products/country:33+Cameroun/categorie:20?Épiceries%20et%20boisson%C2%A0");
    }
    if (newValue === 3) {
      this.props.history.push("/products/country:33+Cameroun/categorie:19?Beauté%20et%20bien-être%C2%A0");
    }
    if (newValue === 4) {
      this.props.history.push("/products/country:33+Cameroun/categorie:17?Jouets,%20enfants%20et%20bébés");
    }
    if (newValue === 5) {
      this.props.history.push("/products/country:33+Cameroun/categorie:21?modes%20&%20vêtements");
    }
    if (newValue === 6) {
      this.props.history.push("/products/country:33+Cameroun/categorie:9?Electroniques");
    }
    if (newValue === 7) {
      this.props.history.push("/products/country:33+Cameroun/categorie:13?Multimedia");
    }
  };


  handleChangeSocialOption = (event) => {
    let str = event.target.value;
    let interest_rate = parseInt(str.replace('%', ''))

    this.setState({ selectedValue: event.target.value });
    this.props.dispatch(selectedValueFunct({ selectedValueStore: event.target.value }))
    this.props.dispatch(economicAccessibility({
      treatment_costs: null,
      social_assistance: parseFloat(((this.calculateTotal() * parseInt(interest_rate)) / 100).toFixed(2)),
      delivery: null,
      interest_rate: null,
      amountCMD: null,
      fromCMD: null,
      totalHtArrondie: null
    }));
  };

  profile() {
    this.props.dispatch(UsersService.profile())
      .then((result) => {
        const data = result
        this.setState({
          whoConstitutesCart: data.real_id,
          donatorHashId_t: data.id,
          nameOfWhoConstitutesCart: data.name,
          emailOfWhoConstitutesCart: data.email,
          phoneOfWhoConstitutesCart: data.phone,
          user_id: data.id
        });
        this.props.dispatch(prodId_t({ __id: data.id, __rid: data.real_id, __pfn: data.firstname + " " + data.lastname }));
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  // handleSubmit(e) {
  //   e.preventDefault();
  //   this.setState({email: "ludovicmoukouri@yahoo.fr"})
  // }
  allCategories = () => {
    this.props.dispatch(ProductService.getAllCategories())
      .then((result) => {
        let data = result.data;

        const categories = this.buildCategoryHierarchy(data);

        let options = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name
          }
        ));
        this.setState({ option_categorie: options, allCategories: categories });
        // this.setState({shops: data});
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  buildCategoryHierarchy = (data) => {

    const categoryMap = {};
    const hierarchy = [];

    // Map categories by slug
    data.forEach(item => {
      categoryMap[item.slug] = {
        name: item.name,
        iconUrl: item.images,
        path: item.url_slug,
        slug: item.slug,
        level: !!item.level ? parseInt(item.level) : 0,
        parent_id: item.parent_id,
        subcategories: []
      };
    });

    // Build hierarchy based on level and url_slug
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.level === 0 || category.level === '') {
        hierarchy.push(category);
      } else {
        const parentSlug = category.path.split('/')[category.level];
        if (categoryMap[parentSlug]) {
          categoryMap[parentSlug].subcategories.push(category);
        }
      }
    });

    // Update path if subcategories length is 0
    Object.keys(categoryMap).forEach(key => {
      const category = categoryMap[key];
      if (category.subcategories.length > 0) {
        delete category.path;
      }
    });

    return hierarchy;
  }

  handleClick(lang) {
    const { i18n } = this.props;
    i18n.changeLanguage(lang);
    this.setState({ langBool: !this.state.langBool });
  }
  checked_t() {
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  checked_rad1_t() {
    if (this.state.checked) {
      this.setState({ checked: true, checked_2: false, checked_3: false });
    }
    this.setState({ checked_2: false, checked_3: false });
  }
  checked_rad2_t() {
    if (this.state.checked_2) {
      this.setState({ checked: false, checked_2: true, checked_3: false });
    }
    this.setState({ checked: false, checked_3: false });
  }
  checked_rad3_t() {
    if (this.state.checked_3) {
      this.setState({ checked: false, checked_2: false, checked_3: true });
    }
    this.setState({ checked: false, checked_2: false });
  }
  toggle() {
    this.setState({ eye_password_bool: !this.state.eye_password_bool })
  }

  check_theme() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // console.log('🎉 Dark mode is supported');
      this.setState({ def_theme: 'dark', theme_bool: true, checked_switch: false })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
      // console.log('🎉 light mode is supported');
      this.setState({ def_theme: 'light', theme_bool: true, checked_switch: true })
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
      this.props.dispatch(changeNavigatorTheme(ciphertext))
      // console.log('ciphertext ', ciphertext)
    }
  }
  for_remove_js(e) {
    e.preventDefault();
  }
  theme_botton_D(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'dark', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('dark'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  theme_botton_W(e, ip) {
    if (ip) {
      e.preventDefault();
    }
    this.setState({ def_theme: 'light', theme_bool: true })
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify('light'), 'key-moimonnom@321').toString();
    this.props.dispatch(changeNavigatorTheme(ciphertext));
    this.setState({ checked_switch: !this.state.checked_switch });
  }
  //  post_login_user() {
  //    this.setState({isLoading: true});
  //    let credentials = {email: this.state.email, password: this.state.password}
  //    this.props.dispatch(AuthService.login(credentials))
  //      .then((result)  => {
  //        this.props.history.push("/");
  //        this.setState({isLoading: false})
  //      })
  //      .catch(({data}) => {
  //          this.setState({show_t: 'show_t'});
  //          console.log('errors ', data);
  //          this.setState({isLoading: false})
  //      });
  // }
  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    const { postregister } = this.state;

    postregister[name] = value;
    if (name === 'email') {
      this.setState({ email_exit: false })
    }
    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors, postregister })
        this.props.dispatch(saveUserInfos({
          postregister
        }))
      });
  }

  handleChangeDistrict = (val) => {
    const name = 'district';
    const value = val;
    const { errors } = this.validator;
    const { postregister } = this.state;

    postregister[name] = value;

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors, postregister })
      });
  }

  allCountries() {
    this.props.dispatch(AuthService.getAllCountries())
      .then((result) => {

        let data = result.data;
        let options = data.map((value, index) => (

          {
            value: { id: value.id, real_id: value.real_id },
            label: this.state.langBool === true ? value.name_fr : value.name_en
          }
        ));
        this.setState({
          isLoading: false,
          showAlert: false,
          alert2: null,
          option_country: options
        });
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  allCitiesCountry(value) {
    this.props.dispatch(AuthService.getCitiesCountry(value))
      .then((result) => {

        let data = result;
        let options = data.map((value, index) => (

          {
            value: value.id,
            label: value.name
          }
        ));
        this.setState({
          isLoading: false,
          showAlert: false,
          alert2: null,
          option_city: options
        });
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  handleSubmit(postregisterData) {
    this.setState({ isLoading: true });
    const { postregister } = this.state;

    let data = {
      name: postregister.lastname + ' ' + postregister.firstname,
      email: postregister.email,
      phone: postregister.phone,
      district: postregister.district,
      // city_id: postregister.city_id,
      city_id: 19,
    }

    navigator.onLine ?
      this.props.dispatch(UsersService.createReceiverProfile(data))
        .then((result) => {
          const postregister = {
            lastname: '',
            firstname: '',
            email: '',
            district: '',
            phone: '',
            country_id: null,
            country: '',
            city_id: null,
            city: ''
          }
          this.setState({ postregister })
        })
        .catch((errors) => {
          console.log('errors ', errors);
        })
      :
      this.setState({ offline: true, isLoading: false })
  }

  async validateAndSubmit(e) {
    e.preventDefault()

    const { postregister } = this.state
    const { errors } = this.validator

    const valid = this.validator.validateAll(postregister)

    if (valid) {
      this.onsubmitNewUser_t(postregister)
    } else {
      this.setState({ errors })
    }
  }

  precedent_t = (e, isValue, label) => {
    if (label === 'premier' && isValue === true) {
      this.setState({
        show_perso_sa: false,
        show_loca_sa: false,
        show_params_sa: false,
      })
    }
    if (label === 'second' && isValue === true) {
      this.setState({
        show_second_tab: false,
        show_first_tab: true,
        dir_btn_suiv_t: true,
        first_nav_btn_t: true,
        second_nav_btn_t: false,
        dir_btn_prec_2_t: false,
        save_t: false
      })
    }
    e.preventDefault();
  }

  gotToParentValue = (value, label) => {
    const { postregister } = this.state;
    if (label === "Francais" || label === "French") {
      const options_fr = [{ value: 'en', label: "Anglais" }];
      const options_eng = [{ value: 'fr', label: "Francais" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("fr");
    }
    if (label === "Anglais" || label === "English") {
      const options_fr = [{ value: 'en', label: "English" }];
      const options_eng = [{ value: 'fr', label: "French" }];
      this.setState({
        langBool: true,
        options_fr: options_fr,
        options_eng
      })
      this.handleClick("en");
    }
    if (label === "Dollar") {
      this.setState({
        device_data: value
      })
    }
    if (label === "date") {
      postregister['date'] = value
      this.setState({ postregister })
    }
    if (label === "phone") {
      postregister['phone'] = value
      this.setState({ postregister })
    }
    if (label === "sexe") {
      postregister['sexe'] = value
      this.setState({ postregister })
    }
    if (label === "country") {
      postregister['country'] = value
      this.setState({ postregister })
      this.allCitiesCountry(value.real_id)
    }
    if (label === "city_id") {
      postregister['city_id'] = value
      this.setState({ postregister })
    }
    if (label === "point_relais") {
      // postregister['point_relais'] = value;
      this.setState({ valueRelayPoint: value });
      // Redux
      this.props.dispatch(relaypointId_t({ relaypointId: value }))
    }
    if (label === "searchR_t") {
      if (value.length >= 2) {
        this.searchOfClients(value)
        this.setState({ searchR_t: value })
      } else {
        this.setState({ searchR_t: value, seacrchList: [] })
        this.props.dispatch(seacrchListAction({ seacrchListStore: [] }))
      }
    }
  }

  onAtHomeChoose = (montant, distanceBeneficiary, typeOfLocatization) => {

    Swal.fire({
      icon: 'success',
      title: 'Livraison à domicile',
      text: 'Vous avez choisi la livraison ' + typeOfLocatization,
      confirmButtonText: 'Continuer',
      customClass: {
        confirmButton: 'swal-button-green'
      }
    }).then((result) => {
      // Vérifiez si l'utilisateur a cliqué sur le bouton "Continuer"
      if (result.isConfirmed) {

        this.findTargetUser();

        this.props.dispatch(saveUserInfos({
          postregister: {
            lastname: '',
            firstname: '',
            email: '',
            district: '',
            phone: '',
            country_id: null,
            country: '',
            city_id: null,
            city: ''
          }
        }))

        this.props.dispatch(navCartStore({
          showPr: false,
          showCart: false,
          showCarteadhesion: false,
          showReceveur: true,
          showPaiement: false,
          realCard: false,
          totalTtcArrondie: this.state.totalHtArrondie
        }))

        this.props.dispatch(typeOfHomeLocalization({
          typeOfHLocatization: typeOfLocatization,
          montantLivraison: montant
        }
        ))

        this.props.dispatch(economicAccessibility({
          treatment_costs: null,
          social_assistance: null,
          delivery: montant,
          interest_rate: null,
          amountCMD: null,
          fromCMD: null,
          totalHtArrondie: null
        }));

        this.props.dispatch(initRelaypointId());
        this.props.dispatch(relaypointId_t({ relaypointId: 21 }));

        this.setState({ montantLivraison: montant, sendCart: false });
        // history.push("/login");
      }
    });

  }

  onHandleRelayPointId = (val) => {
    this.setState({ valueRelayPoint: val.value });
    // Redux
    this.props.dispatch(relaypointId_t({ relaypointId: val.value }));

    Swal.fire({
      icon: 'success',
      title: 'Point Relay choisi',
      text: 'Lieu du point relay ' + val.label,
      confirmButtonText: 'Continuer',
      customClass: {
        confirmButton: 'swal-button-green'
      }
    }).then((result) => {
      // Vérifiez si l'utilisateur a cliqué sur le bouton "Continuer"
      if (result.isConfirmed) {
        this.props.dispatch(saveUserInfos({
          postregister: {
            lastname: '',
            firstname: '',
            email: '',
            district: '',
            phone: '',
            country_id: null,
            country: '',
            city_id: null,
            city: ''
          }
        }))

        this.props.dispatch(navCartStore({
          showPr: false,
          showCart: false,
          showCarteadhesion: false,
          showReceveur: true,
          showPaiement: false,
          realCard: false,
          totalTtcArrondie: this.state.totalHtArrondie
        }))

        this.props.dispatch(typeOfHomeLocalization({
          typeOfHLocatization: "relayPoint",
          montantLivraison: 0
        }
        ))

        this.setState({
          montantLivraison: 0,
          // sendCart: false
        })
        // history.push("/login");
      }
    });



  }

  searchOfClients = (value) => {
    this.props.dispatch(UsersService.getSearchOfClients(this.state.user_id, value))
      .then((result) => {
        let data = result.data;
        this.setState({ seacrchList: data });
        this.props.dispatch(seacrchListAction({ seacrchListStore: data }))
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  onDropdownBtn_t = () => {
    this.setState({ dropdownBtn_t: !this.state.dropdownBtn_t, interval_error: false })
  }


  allProducts = () => {
    this.props.dispatch(ProductService.getAllProducts())
      .then((result) => {
        let data = result.data;
        this.setState({ products: data });
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  onQty_t = (e, real_id, price, id, reduction, totalQty, qty, image, productName, shopName, country_real_id, index) => {
    const { coefAndPrice, qtyValueArr_t } = this.state;
    const { cartValue } = this.props;
    if (e.target.value >= 1 && e.target.value <= totalQty) {
      // Debut Insertion dans le local storage
      let productObject = {
        shopName: shopName,
        productName: productName,
        price: price,
        reduction: reduction,
        usedPrice: reduction === 0 ? price : reduction,
        id: id,
        image: image,
        totalQty: totalQty,
        qty: e.target.value,
        real_id: real_id,
        country_real_id: country_real_id,


        // treatment_costs: this.props.showCarteadhesionGS ? 2 : 0,
        // social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)) : 0,
        // delivery: this.props.montantLivraisonSotre,
        // interest_rate: parseInt(str.replace('%', '')),

      }
      // Fin Insertion dans le local storage
      this.props.showCartValue.length > 0 ?
        this.props.dispatch(cartUpdateTab_t({ cart: "showCartValue", id: id, productObject }))
        :
        this.props.dispatch(cartUpdateTab_t({ cart: "cartValue", id: id, productObject }))
      let indexCatTab = coefAndPrice.map((v) => v.id).indexOf(id);
      coefAndPrice.splice(indexCatTab, 1);

      qtyValueArr_t.splice(parseInt(index), 1, parseInt(e.target.value));
      let qty_t = qtyValueArr_t.reduce(function (a, b) {
        return a + b;
      }, 0);

      coefAndPrice.push({
        id: id,
        price: price,
        reduction: reduction,
        qtyValueArr_t: qtyValueArr_t,
        usedPrice: reduction === 0 ? price : reduction,
        // productsPrice: parseInt(e.target.value) * (parseInt(price) - parseInt(reduction))
        productsPrice: parseInt(e.target.value) * parseInt(price)
      })
      this.setState({
        qtyValueArr_t: qtyValueArr_t,
        qty_t: qty_t,
        coefAndPrice: coefAndPrice
      });
    } else {
      if (e.target.value < 1) {
        qtyValueArr_t[index] = 1
        this.setState({ qtyValueArr_t: qtyValueArr_t })
      }
      if (parseInt(e.target.value) > parseInt(qty)) {
        //  qtyValueArr_t[index] = qty

        //  let indexCatTab = coefAndPrice.map((v) => v.id).indexOf(id);
        // coefAndPrice.splice(indexCatTab, 1);

        // let qty_t = qtyValueArr_t.reduce(function(a, b){
        //         return a + b;
        //     }, 0);
        // coefAndPrice.push({
        //   id: id, 
        //   price: price, 
        //   reduction: reduction, 
        //   qtyValueArr_t: qtyValueArr_t, 
        //   productsPrice: parseInt(e.target.value)*parseInt(price)
        // })
        // this.setState({
        //   qtyValueArr_t: qtyValueArr_t,
        //   qty_t: qty_t,
        //   coefAndPrice: coefAndPrice
        // });
        // console.log('Aucune Action');
      }
    }
  }

  goToParentCoefAndPrice = (value) => {
    const { coefAndPrice, qtyValueArr_t } = this.state;
    if (coefAndPrice.map((v) => v.id).indexOf(value.id) === -1) {
      coefAndPrice.push(value)
      qtyValueArr_t.push(1)
      let qty_t = qtyValueArr_t.reduce(function (a, b) {
        return a + b;
      }, 0);
      this.setState({
        coefAndPrice: coefAndPrice,
        qtyValueArr_t: qtyValueArr_t,
        qty_t: qty_t
      })
    }
  }

  onAddProduct = (e) => {
    // Here we use redux for increments and decrements  number in panier icon
    e.preventDefault();
  }
  allPointRelais = () => {
    this.props.dispatch(PointRelaisService.getAllPointRelais())
      .then((result) => {
        let data = result.data;
        let options = data.map((value, index) => (
          {
            value: value.real_id,
            label: value.name,
            open_hour: value.open_hour,
            close_hour: value.close_hour,
            description: value.description,
            isRelayPointValue: true
          }
        ));
        this.props.dispatch(relayPointValue({ optionsRP: options }))
        this.setState({ optionPointRelais_t: options });
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  onPayment = (e) => {
    alert("Paiement inactif pour le moment");
    this.props.dispatch(initRelayPointData())
    this.onReturnCart_t(e);

    // this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: false }))
    // this.props.dispatch(navCartStore({
    //   showPr: false,
    //   showCart: true,
    //   showCarteadhesion: false,
    //   showReceveur: false,
    //   showPaiement: false,
    //   realCard: false
    // }))
    // this.setState({
    //   showPr: false,
    //   showCart: true,
    //   showCarteadhesion: false,
    //   showReceveur: false,
    //   realCard: true,
    //   membershipCardClick: false,
    //   showPaiement: false
    // });

    this.props.history("/")
    e.preventDefault()
  }

  onMembershipCardClick = () => {
    this.setState({ membershipCardClick: false })
  }

  onshowCarteadhesion_t = (e) => {
    if (this.props.isAuthenticated) {
      this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: true }))
      this.props.dispatch(navCartStore({
        showPr: false,
        showCart: false,
        showCarteadhesion: true,
        showReceveur: false,
        showPaiement: false,
        realCard: false
      }))
      // if (!this.props.optionsRPStore) {
      //   this.allPointRelais();
      // }

      this.setState({
        showCarteadhesion: true,
        showPr: false,
        showCart: false,
        showReceveur: false,
        showPaiement: false,
        realCard: false,
        totalTtcArrondie: this.state.totalHtArrondie
        // showPr: this.props.cartValue.relaypointId !== undefined ? false : true, 
        // showReceveur: this.props.cartValue.relaypointId !== undefined ? true : false,
      });
    } else {
      this.setState({
        showAlert: true
      });
    }
  }

  onNotshowCarteadhesion_t = (e) => {
    if (this.props.isAuthenticated) {
      this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: false }))
      // if (!this.props.optionsRPStore) {
      //   this.allPointRelais();
      // }
      this.props.dispatch(navCartStore({
        showPr: true,
        showCart: false,
        showCarteadhesion: false,
        showReceveur: false,
        showPaiement: false,
        realCard: false
      }))

      this.setState({
        showPr: true,
        showCart: false,
        showCarteadhesion: false,
        showReceveur: false,
        showPaiement: false,
        realCard: false,
        totalTtcArrondie: this.state.totalHtArrondie

        // showPr: this.props.cartValue.relaypointId !== undefined ? false : true, 
        // showReceveur: this.props.cartValue.relaypointId !== undefined ? true : false,
      });
    } else {
      this.setState({
        showAlert: true
      });
    }

    e.preventDefault();
  }

  onShowPointRelais_t = (e) => {
    if (this.props.isAuthenticated) {
      // if (!this.props.optionsRPStore) {
      //   this.allPointRelais();
      // }
      this.props.dispatch(navCartStore({
        showPr: true,
        showCart: false,
        showCarteadhesion: false,
        showReceveur: false,
        showPaiement: false,
        realCard: false
      }))
      this.setState({
        showPr: true,
        showCart: false,
        showCarteadhesion: false,
        showReceveur: false,
        showPaiement: false,
        realCard: false,
        totalTtcArrondie: this.state.totalHtArrondie
        // showPr: this.props.cartValue.relaypointId !== undefined ? false : true, 
        // showReceveur: this.props.cartValue.relaypointId !== undefined ? true : false,
      });
    } else {
      this.setState({
        showAlert: true
      });
    }

    e.preventDefault();
  }

  onChooseSocialAssistance = (isDeferredPayment, typeOfDeferredPayment, isSocialAssistanceOption, membershipCardClick) => {
    this.setState({
      isDeferredPayment,
      typeOfDeferredPayment,
      isSocialAssistanceOption,
      membershipCardClick
    })
    if (typeOfDeferredPayment === 4) {
      this.setState({
        totalTtcArrondie: this.state.totalHtArrondie
      })
    }

    if (isDeferredPayment === true) {
      this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: true }))

      if (typeOfDeferredPayment === 2) {
        this.props.dispatch(socialAssistanceObject({ isSocialAssistanceOption, paymentType: 'aide social', isSocialPayment: true }))
        let interestR = (parseFloat((this.calculateTotal() + 2 + parseInt(this.props.delivery) + ((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100)).toFixed(2)) * 10) / 100
        let tauxInteretArrondie = parseFloat(interestR).toFixed(2)

        this.insertInEconomicAccessibility(2,
          parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)), null, null, null, false, null);
      }

      if (typeOfDeferredPayment === 3) {
        this.props.dispatch(socialAssistanceObject({ isSocialAssistanceOption, paymentType: 'sans aide social', isSocialPayment: false }))
        let interestR = (parseFloat((this.calculateTotal() + 2 + parseInt(this.props.delivery)).toFixed(2)) * 8) / 100
        let tauxInteretArrondie = parseFloat(interestR).toFixed(2)

        this.insertInEconomicAccessibility(2,
          parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)), null, null, null, false, null);
      }

    }
    if (isDeferredPayment === false) {
      this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: false }))
      this.props.dispatch(socialAssistanceObject({ isSocialAssistanceOption, paymentType: 'direct', isSocialPayment: false }))
    }


    // this.props.dispatch(economicAccessibility({
    //   treatment_costs: null,
    //   social_assistance: null,
    //   delivery: montant,
    //   interest_rate: null,
    //   amountCMD: null,
    //   fromCMD: null,
    //   totalHtArrondie: null
    // }));

  }

  onShowPointRelaisFromPDiff_t = (e) => {

    this.setState({
      isDeferredPayment: true
    }, () => {

      if (this.props.isAuthenticated) {
        if (this.state.isDeferredPayment === true && this.state.typeOfDeferredPayment !== 0) {

          if (this.state.typeOfDeferredPayment === 2 && this.props.isSocialAssistanceOptionStore === true) {

            // if (!this.props.optionsRPStore) {
            //   this.allPointRelais();
            // }
            this.props.dispatch(navCartStore({
              showPr: true,
              showCart: false,
              showCarteadhesion: false,
              showReceveur: false,
              showPaiement: false,
              realCard: false
            }))

            this.setState({
              showPr: true,
              showCart: false,
              showCarteadhesion: false,
              showReceveur: false,
              showPaiement: false,
              realCard: false,
              membershipCardClick: false,
              isDeferredPayment: false,
              totalTtcArrondie: this.state.totalHtArrondie
            });

          }

          if (this.state.typeOfDeferredPayment === 3 && this.props.isSocialAssistanceOptionStore === false) {

            // if (!this.props.optionsRPStore) {
            //   this.allPointRelais();
            // }
            this.props.dispatch(navCartStore({
              showPr: true,
              showCart: false,
              showCarteadhesion: false,
              showReceveur: false,
              showPaiement: false,
              realCard: false
            }))
            this.setState({
              showPr: true,
              showCart: false,
              showCarteadhesion: false,
              showReceveur: false,
              showPaiement: false,
              realCard: false,
              membershipCardClick: false,
              isDeferredPayment: false,
              totalTtcArrondie: this.state.totalHtArrondie
            });

          }
          else {
            this.setState({ membershipCardClick: true });
          }

        }

      } else {
        this.setState({
          showAlert: true
        });
      }

    })



    e.preventDefault();
  }

  onReturnCart_t = (e) => {
    this.props.dispatch(socialAssistanceObject({ isSocialAssistanceOption: false, paymentType: 'direct', isSocialPayment: false }))
    this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: false }))
    this.props.dispatch(navCartStore({
      showPr: false,
      showCart: true,
      showCarteadhesion: false,
      showReceveur: false,
      showPaiement: false,
      realCard: false
    }))
    this.setState({
      showPr: false,
      showCart: true,
      showCarteadhesion: false,
      showReceveur: false,
      realCard: true,
      membershipCardClick: false,
      showPaiement: false,
      totalTtcArrondie: this.state.totalHtArrondie
    });
    e.preventDefault();
  }

  onReturnShop_t = (e) => {
    this.props.dispatch(onClickOnCmd({ clickOnCmd: false }));
    e.preventDefault();
  }

  onMembershipCard_t = (e) => {
    this.props.dispatch(navCartStore({
      showPr: false,
      showCart: false,
      showCarteadhesion: true,
      showReceveur: false,
      showPaiement: false,
      realCard: false
    }))

    this.setState({
      showPr: false,
      showCart: false,
      showCarteadhesion: true,
      showReceveur: false,
      showPaiement: false,
      membershipCardClick: false,
      isDeferredPayment: false,
      totalTtcArrondie: this.state.totalHtArrondie
    });
    e.preventDefault();
  }

  onReturnRelaisPoint_t = (e) => {
    this.props.dispatch(navCartStore({
      showPr: true,
      showCart: false,
      showCarteadhesion: false,
      showReceveur: false,
      showPaiement: false,
      realCard: false
    }))

    this.setState({
      showPr: true,
      showCart: false,
      showCarteadhesion: false,
      showReceveur: false,
      membershipCardClick: false,
      showPaiement: false,
      totalTtcArrondie: this.state.totalHtArrondie
    });
    e.preventDefault();
  }

  onShowReceveur_t = (e, relaypointIdArray) => {

    if (!!this.props.relaypointId || parseInt(this.props.montantLivraisonSotre) !== 0) {

      this.props.dispatch(saveUserInfos({
        postregister: {
          lastname: '',
          firstname: '',
          email: '',
          district: '',
          phone: '',
          country_id: null,
          country: '',
          city_id: null,
          city: ''
        }
      }))

      this.props.dispatch(navCartStore({
        showPr: false,
        showCart: false,
        showCarteadhesion: false,
        showReceveur: true,
        showPaiement: false,
        realCard: false
      }))

      this.setState({
        showPr: false,
        showCart: false,
        showCarteadhesion: false,
        showReceveur: true,
        showPaiement: false,
        realCard: false,
        rpointIsNotchoosed: false,
        membershipCardClick: false,
        valueRelayPoint: this.props.cartValue.relaypointId !== undefined ? relaypointIdArray : this.props.relaypointId,
        totalTtcArrondie: this.state.totalHtArrondie
      });

    } else {
      this.setState({ rpointIsNotchoosed: true })
    }

    e.preventDefault();
  }

  onShowPayment_t = (e) => {
    e.preventDefault();
    // this.props.dispatch(navCartStore({
    //   showPr: false,
    //   showCart: false,
    //   showCarteadhesion: false,
    //   showReceveur: false,
    //   showPaiement: this.state.sendCart ? true : false,
    //   realCard: false
    // }))

    // this.setState({
    //   showPr: false,
    //   showCart: false,
    //   showReceveur: false,
    //   showCarteadhesion: false,
    //   showPaiement: this.state.sendCart ? true : false,
    //   showBtnTrue2: true,
    //   creator_name: this.state.nameChoice
    // });

    this.props.dispatch(navCartStore({
      showPr: false,
      showCart: false,
      showCarteadhesion: false,
      showReceveur: false,
      showPaiement: true,
      realCard: false
    }))

    this.setState({
      showPr: false,
      showCart: false,
      showReceveur: false,
      showCarteadhesion: false,
      showPaiement: true
    });

    // this.props.dispatch(socialAssistanceObject({ isSocialAssistanceOption: this.props.isSocialAssistanceOptionStore, paymentType: this.props.paymentTypeStore, isSocialPayment: this.props.isSocialPaymentStore }))
    // this.props.dispatch(socialAssistanceObject({ isSocialAssistanceOption: false, paymentType: 'direct', isSocialPayment: false }))
    this.props.dispatch(selectedValueFunct({ selectedValueStore: '3%' }));

  }


  onsubmitExitingUsers_t = (e) => {
    alert("e");
    e.preventDefault();
    const { cartValue, showCartValue } = this.props;
    const { amountUserLink, totalTtcArrondie } = this.state;
    let sum_t = 0;
    const { coefAndPrice } = this.state;

    let commandLines = showCartValue.length > 0 ?
      showCartValue.map((v) => ({ hash_id: v.id, id: v.real_id, quantity: v.qty }))
      :
      cartValue.map((v) => ({ hash_id: v.id, id: v.real_id, quantity: v.qty }))

    coefAndPrice.map((v) => { sum_t += v.productsPrice })

    let arrayQty = showCartValue.length > 0 ? showCartValue.length > 0 && showCartValue.map((v, i) => v.qty)
      : cartValue.length > 0 && cartValue.map((v, i) => v.qty)

    let sumQtys = arrayQty.length > 0 && arrayQty.reduce(function (a, b) {
      return a + b;
    }, 0);

    let dataCommands = {};
    let str = this.props.selectedValueStore;
    this.state.sendCart === false ?
      dataCommands = {
        treatment_costs: this.props.showCarteadhesionGS ? 2 : 0,
        // social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)) : this.props.selectedValueStore,
        social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)) : 0,
        delivery: this.props.montantLivraisonSotre,
        // interest_rate: this.props.selectedValueStore !== 0 ? parseInt(str.replace('%', '')): str,
        interest_rate: this.props.isSocialAssistanceOptionStore ? parseInt(str.replace('%', '')) : 0,
        amount: totalTtcArrondie,
        quantity: sumQtys,
        relaypoint_id: this.props.relaypointId,
        receiver_id: parseInt(this.props.receiverUserReal_idStore),
        donator_id: parseInt(this.state.whoConstitutesCart),
        commandLines: JSON.stringify(commandLines),
        is_pay_by_creator: true,
        address: !!this.props.distanceBeneficiarySTore && this.props.distanceBeneficiarySTore.isAtHome === "Yes" ? this.props.distanceBeneficiarySTore.adresse : null
      }
      :
      dataCommands = {
        treatment_costs: this.props.showCarteadhesionGS ? 2 : 0,
        // social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)) : this.props.selectedValueStore,
        social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)) : 0,
        delivery: this.props.montantLivraisonSotre,
        interest_rate: this.props.isSocialAssistanceOptionStore ? parseInt(str.replace('%', '')) : 0,
        amount: totalTtcArrondie,
        quantity: sumQtys,
        relaypoint_id: this.props.relaypointId,
        receiver_id: this.state.whoConstitutesCart,
        donator_id: parseInt(this.props.receiverUserReal_idStore),
        commandLines: JSON.stringify(commandLines),
        is_pay_by_creator: false,
        address: !!this.props.distanceBeneficiarySTore && this.props.distanceBeneficiarySTore.isAtHome === "Yes" ? this.props.distanceBeneficiarySTore.adresse : null
      }
    if (amountUserLink !== null && parseInt(sum_t) > parseInt(amountUserLink)) {
      alert("Montant max depassé");
    } else {
      this.props.dispatch(CartService.commands(dataCommands))
        .then((result) => {
          const data = result.data

          this.renitCardInformation();

          this.props.dispatch(initRelayPointData());

          // if (this.state.sendCart === true) { 
          if (this.props.isUserProf === true) {
            this.props.history("products/country:33+Cameroun")

          } else {

            this.props.dispatch(navCartStore({
              showPr: false,
              showCart: this.state.sendCart ? true : false,
              showCarteadhesion: false,
              showReceveur: false,
              showPaiement: true,
              realCard: false
            }))

            this.setState({
              showPr: false,
              showCart: this.state.sendCart ? true : false,
              showReceveur: false,
              showCarteadhesion: false,
              showPaiement: true,
              showBtnTrue2: true,
              creator_name: this.state.nameChoice,
              // commandId: data.data.real_id,
              // totalTtcArrondie: this.state.totalHtArrondie
            });

            console.log("Rediriger vers la page de paiement", this.state.sendCart, this.props.isUserProf);
          }



          // this.onPayment(e);
        })
        .catch(({ data }) => {
          console.log('errors ', data);
        });
    }
  }


  onsubmitExitingUsers_tAns = (e) => {
    e.preventDefault();
    let totalSum = this.calculateTotal();

    const { cartValue, showCartValue } = this.props;
    const { isUserProf, amountUserLink, selectedValue, isSocialAssistanceOption, totalTtcArrondie } = this.state;
    let sum_t = 0
    const { qtyValueArr_t, coefAndPrice } = this.state;

    let commandLines = showCartValue.length > 0 ?
      showCartValue.map((v) => ({ hash_id: v.id, id: v.real_id, quantity: v.qty }))
      :
      cartValue.map((v) => ({ hash_id: v.id, id: v.real_id, quantity: v.qty }))

    // coefAndPrice.map((v)=> {sum_t += parseInt(v.productsPrice) - parseInt(v.reduction) })
    coefAndPrice.map((v) => { sum_t += v.productsPrice })

    let arrayQty = showCartValue.length > 0 ? showCartValue.length > 0 && showCartValue.map((v, i) => v.qty)
      : cartValue.length > 0 && cartValue.map((v, i) => v.qty)

    // let arrayQty = cartValue.length > 0 && cartValue.map((v, i) => v.qty);
    let sumQtys = arrayQty.length > 0 && arrayQty.reduce(function (a, b) {
      return a + b;
    }, 0);

    let dataCommands = {};
    let str = this.props.selectedValueStore;
    if (this.state.isUserProf) {
      dataCommands = {
        treatment_costs: this.props.showCarteadhesionGS ? 2 : 0,
        social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)) : this.props.selectedValueStore,
        delivery: this.props.montantLivraisonSotre,
        interest_rate: parseInt(str.replace('%', '')),
        amount: totalTtcArrondie,
        // amount: this.props.showCarteadhesionGS ?
        //   isSocialAssistanceOption ?
        //     parseFloat((totalSum + 2 + ((totalSum * parseInt(selectedValue)) / 100)).toFixed(2))
        //     :
        //     parseFloat((totalSum + 2).toFixed(2))
        //   :
        //   parseFloat((totalSum).toFixed(2)),
        quantity: sumQtys,
        // relaypoint_id: this.state.valueRelayPoint,
        relaypoint_id: this.props.relaypointId,
        receiverprofile_id: parseInt(this.state.receiverUserReal_id),
        // donator_id: parseInt(this.state.whoConstitutesCart),  
        donator_id: parseInt(this.props.prodId_t.__rid),
        commandLines: JSON.stringify(commandLines),
        is_pay_by_creator: true,
        address: !!this.props.distanceBeneficiarySTore && this.props.distanceBeneficiarySTore.isAtHome === "Yes" ? this.props.distanceBeneficiarySTore.adresse : null
      }
    } else {
      this.state.sendCart ?
        dataCommands = {
          treatment_costs: this.props.showCarteadhesionGS ? 2 : 0,
          social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)) : this.props.selectedValueStore,
          delivery: this.props.montantLivraisonSotre,
          interest_rate: parseInt(str.replace('%', '')),
          amount: totalTtcArrondie,
          // amount: this.props.showCarteadhesionGS ?
          //   isSocialAssistanceOption ?
          //     parseFloat((totalSum + 2 + ((totalSum * parseInt(selectedValue)) / 100)).toFixed(2))
          //     :
          //     parseFloat((totalSum + 2).toFixed(2))
          //   :
          //   parseFloat((totalSum).toFixed(2)),
          quantity: sumQtys,
          //  relaypoint_id: this.state.valueRelayPoint, 
          relaypoint_id: this.props.relaypointId,
          // receiver_id: parseInt(this.state.receiverUserReal_id),
          receiver_id: parseInt(this.props.receiverUserReal_idStore),
          donator_id: parseInt(this.state.whoConstitutesCart),
          commandLines: JSON.stringify(commandLines),
          is_pay_by_creator: true,
          address: !!this.props.distanceBeneficiarySTore && this.props.distanceBeneficiarySTore.isAtHome === "Yes" ? this.props.distanceBeneficiarySTore.adresse : null
        }
        :
        dataCommands = {
          treatment_costs: this.props.showCarteadhesionGS ? 2 : 0,
          social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)) : this.props.selectedValueStore,
          delivery: this.props.montantLivraisonSotre,
          interest_rate: parseInt(str.replace('%', '')),
          amount: totalTtcArrondie,
          // amount: this.props.showCarteadhesionGS ?
          //   isSocialAssistanceOption ?
          //     parseFloat((totalSum + 2 + ((totalSum * parseInt(selectedValue)) / 100)).toFixed(2))
          //     :
          //     parseFloat((totalSum + 2).toFixed(2))
          //   :
          //   parseFloat((totalSum).toFixed(2)),
          quantity: sumQtys,
          //  relaypoint_id: this.state.valueRelayPoint,
          relaypoint_id: this.props.relaypointId,
          receiver_id: this.state.whoConstitutesCart,
          // donator_id: this.state.receiverUserReal_id,
          donator_id: parseInt(this.props.receiverUserReal_idStore),
          commandLines: JSON.stringify(commandLines),
          is_pay_by_creator: false,
          address: !!this.props.distanceBeneficiarySTore && this.props.distanceBeneficiarySTore.isAtHome === "Yes" ? this.props.distanceBeneficiarySTore.adresse : null
        }
    }
    if (amountUserLink !== null && parseInt(sum_t) > parseInt(amountUserLink)) {
      alert("Montant max depassé");
    } else {
      this.props.dispatch(CartService.commands(dataCommands))
        .then((result) => {
          const data = result.data

          this.renitCardInformation();

          this.props.dispatch(initRelayPointData());

          if (this.state.sendCart === true) {
            this.props.history("products/country:33+Cameroun")

          } else {

            this.props.dispatch(navCartStore({
              showPr: false,
              showCart: this.state.sendCart ? true : false,
              showCarteadhesion: false,
              showReceveur: false,
              showPaiement: false,
              realCard: false
            }))

            this.setState({
              showPr: false,
              showCart: this.state.sendCart ? true : false,
              showReceveur: false,
              showCarteadhesion: false,
              showPaiement: false,
              showBtnTrue2: true,
              creator_name: this.state.nameChoice,
              // commandId: data.data.real_id,
              // totalTtcArrondie: this.state.totalHtArrondie
            });

            console.log("Rediriger vers la page de paiement");
          }



          // this.onPayment(e);
        })
        .catch(({ data }) => {
          console.log('errors ', data);
        });
    }
  }

  renitCardInformation = () => {

    this.props.dispatch(cartInitTab_t([]));
    this.props.dispatch(economicAccessibility({
      treatment_costs: 0,
      social_assistance: 0,
      delivery: 0,
      interest_rate: 0,
      amountCMD: 0,
      fromCMD: false,
      totalHtArrondie: 0
    }));
    this.props.dispatch(socialAssistanceObject({
      isSocialAssistanceOption: false,
      paymentType: 'direct',
      isSocialPayment: false
    }))
    this.props.dispatch(onClickOnCmd({ clickOnCmd: false }));
    this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: false }));

    this.props.dispatch(typeOfHomeLocalization({
      typeOfHLocatization: "No data",
      montantLivraison: 0
    }));

    // this.props.dispatch(selectedValueFunct({ selectedValueStore: 0 }))

  }

  onsubmitNewUser_t = (e) => {
    const { postregister } = this.state;

    const { cartValue, showCartValue } = this.props;
    let sum_t = 0
    const { qtyValueArr_t, coefAndPrice } = this.state;

    let commandLines = showCartValue.length > 0 ?
      showCartValue.map((v) => ({ id: v.real_id, quantity: v.qty }))
      :
      cartValue.map((v) => ({ id: v.real_id, quantity: v.qty }))

    coefAndPrice.map((v) => { sum_t += v.productsPrice })

    let qty_t = qtyValueArr_t.reduce(function (a, b) {
      return a + b;
    }, 0);

    let str = this.props.selectedValueStore;

    const dataCommands = {
      treatment_costs: this.props.showCarteadhesionGS ? 2 : 0,
      social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(((this.calculateTotal() * parseInt(this.props.selectedValueStore)) / 100).toFixed(2)) : this.props.selectedValueStore,
      delivery: this.props.montantLivraisonSotre,
      interest_rate: parseInt(str.replace('%', '')),
      amount: sum_t,
      quantity: qtyValueArr_t[0],
      //  relaypoint_id: this.state.valueRelayPoint,
      relaypoint_id: this.props.relaypointId,
      // receiverprofile_id: this.state.is_pay_by_creator ? parseInt(this.state.whoConstitutesCart) : parseInt(this.state.receiverUserReal_id) ,
      donator_id: this.state.is_pay_by_creator ? parseInt(this.props.prodId_t.__rid) : parseInt(this.state.receiverUserReal_id),
      commandLines: JSON.stringify(commandLines),
      name: this.state.is_pay_by_creator ? postregister.lastname + ' ' + postregister.firstname : this.state.nameOfWhoConstitutesCart,
      // email: '',
      phone: this.state.is_pay_by_creator ? postregister.phone : this.state.phoneOfWhoConstitutesCart,
      // city_id: cartValue,
      // district: cartValue,
      email: postregister.email,
      // city_id: postregister.city_id,
      city_id: 19,
      district: postregister.district,
      is_pay_by_creator: this.state.is_pay_by_creator,
      address: !!this.props.distanceBeneficiarySTore && this.props.distanceBeneficiarySTore.isAtHome === "Yes" ? this.props.distanceBeneficiarySTore.adresse : null
    }
    this.props.dispatch(CartService.commands(dataCommands))
      .then((result) => {
        const data = result.data;

        this.props.dispatch(navCartStore({
          showPr: false,
          showCart: false,
          showCarteadhesion: false,
          showReceveur: false,
          showPaiement: true,
          realCard: false
        }))

        this.setState({
          showPr: false,
          showCarteadhesion: false,
          showCart: false,
          showReceveur: false,
          showPaiement: true,
          showBtnTrue: true,
          creator_name: this.state.is_pay_by_creator ? postregister.lastname + ' ' + postregister.firstname : this.state.nameOfWhoConstitutesCart,
          commandId: data.data.real_id
        });
        this.props.dispatch(cartInitTab_t([]));

      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });

    e.preventDefault();
  }

  creatorCreateUserProfile = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const { postregister } = this.state;
    let data = {
      firstname: postregister.firstname,
      lastname: postregister.lastname,
      email: postregister.email,
      phone: postregister.phone,
      address: postregister.district,
      full_name: postregister.firstname + ' ' + postregister.lastname,
      created_by: this.props.prodId_t?.__rid,
    }
    navigator.onLine ?
      this.props.dispatch(UsersService.creatorCreateUserProfile(data))
        .then((result) => {
          const data = result.data;
          console.log('receiverUserReal_idD ', data);
          const postregister = {
            lastname: '',
            firstname: '',
            email: '',
            district: '',
            phone: '',
            country_id: null,
            country: '',
            city_id: null,
            city: ''
          }
          this.setState({ postregister });
          this.props.dispatch(existantReceiverUser({
            amountUserLink: null,
            receiverUserReal_id: data.real_id,
            isUserProf: null,
            nameChoice: null
          }))
          this.onShowPayment_t(e);
        })
        .catch((errors) => {
          console.log('errors ', errors);
        })
      :
      this.setState({ offline: true, isLoading: false })
  }

  onShowReceveurRelais_t = (e) => {

    const { cartValue, showCartValue } = this.props;
    let sum_t = 0
    const { qtyValueArr_t, coefAndPrice } = this.state;

    let commandLines = showCartValue.length > 0 ?
      showCartValue.map((v) => ({ id: v.real_id, quantity: v.qty }))
      :
      cartValue.map((v) => ({ id: v.real_id, quantity: v.qty }))

    // let commandLines = cartValue.map((v)=> ({id: v.real_id,  quantity: v.qty}))

    coefAndPrice.map((v) => { sum_t += v.productsPrice })

    let qty_t = qtyValueArr_t.reduce(function (a, b) {
      return a + b;
    }, 0);
    const dataCommands = {
      amount: sum_t,
      quantity: qtyValueArr_t[0],
      //  relaypoint_id: this.state.valueRelayPoint,
      relaypoint_id: this.props.relaypointId,
      // receiverprofile_id: this.state.is_pay_by_creator ? parseInt(this.state.whoConstitutesCart) : parseInt(this.state.receiverUserReal_id) ,
      donator_id: this.state.is_pay_by_creator ? parseInt(this.state.receiverUserReal_id) : parseInt(this.state.whoConstitutesCart),
      commandLines: JSON.stringify(commandLines),
      name: this.state.is_pay_by_creator ? this.state.firstname + ' ' + this.state.lastname : this.state.nameOfWhoConstitutesCart,
      // email: '',
      phone: this.state.is_pay_by_creator ? this.state.phone : this.state.phoneOfWhoConstitutesCart,
      // city_id: cartValue,
      // district: cartValue,
      email: 'user3@gmail.com',
      city_id: 2,
      district: 'Rue equinox',
      is_pay_by_creator: this.state.is_pay_by_creator,
    }
    this.props.dispatch(CartService.commands(dataCommands))
      .then((result) => {
        // console.log(result);
      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
    // console.log('cartValue ', dataCommands)
    // this.setState({
    //   showCarteadhesion: false, 
    //   showCart: false,
    //   showReceveur: true,
    //   showPaiement: false
    // });

    e.preventDefault();
  }

  onDeleteProduct = (e, real_id) => {
    this.props.showCartValue.length > 0 ?
      this.props.dispatch(deleteCartTab_t({ cart: "showCartValue", real_id: real_id }))
      :
      this.props.dispatch(deleteCartTab_t({ cart: "cartValue", real_id: real_id }));

    if (this.props.cartValue.length === 0) {
      this.props.dispatch(onClickOnCmd({ clickOnCmd: false }));

      this.props.dispatch(economicAccessibility({
        treatment_costs: 0,
        social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(this.props.social_assistanceStore) : 3,
        delivery: 0,
        interest_rate: 0,
        fromCMD: false
      }));

      this.props.dispatch(socialAssistanceObject({
        isSocialAssistanceOption: false,
        paymentType: 'direct',
        isSocialPayment: false
      }));

      this.props.dispatch(showCarteadhesionStore({ showCarteadhesionGlobalState: false }))


    }

    this.setState({ ls_t: !this.state.ls_t })
    e.preventDefault();
  }

  searchClientLink = () => {
    this.props.dispatch(UsersService.searchClientLink(this.state.donatorHashId_t, this.state.searchR_t))
      .then((result) => {
        let receivers = result.receivers.data;
        let userLinks = result.userLinks.data;
        let seacrchList = receivers.concat(userLinks);
        this.setState({ seacrchList: seacrchList });
        this.props.dispatch(seacrchListAction({ seacrchListStore: seacrchList }))
        // console.log('Users client ', data)
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }
  getUserLink_t = () => {
    this.props.dispatch(CartService.userLink_t())
      .then((result) => {
        let data = result.data;
        this.setState({ seacrchList: data });
        this.props.dispatch(seacrchListAction({ seacrchListStore: data }))
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  parent_categorieLabel = (categorieLabel) => {
    this.setState({ categorieLabel: categorieLabel });
  }
  parent_add_categorie = (value) => {
    const { option_categorie } = this.state;
    option_categorie.push(value);

    this.setState({ bool_val: true, option_categorie: option_categorie, categorie_value: value });
  }
  onToggle_t = () => {
    this.setState({ toggleBool_t: !this.state.toggleBool_t });
  }
  onLogout = () => {
    // this.setState({isLoading: true});
    this.props.dispatch(AuthService.logout())
      .then((result) => {
        this.props.history.push("/");
        this.props.dispatch(navCartStore({
          showPr: false,
          showCart: true,
          showCarteadhesion: false,
          showReceveur: false,
          showPaiement: false,
          realCard: false
        }))

      })
      .catch(({ data }) => {
        console.log('errors ', data);
      });
  }

  onUserDetails = (amount, real_id, sendCart, name) => {
    console.log('onUserDetails ', amount, real_id, sendCart, name);
    this.props.dispatch(existantReceiverUser({
      amountUserLink: amount,
      receiverUserReal_id: real_id,
      isUserProf: sendCart,
      nameChoice: name
    }))

    this.setState({
      amountUserLink: amount,
      receiverUserReal_id: real_id,
      isUserProf: sendCart,
      nameChoice: name
    });

    // this.scrollToMyRef();
  }

  scrollToMyRef = () => {
    // return window.scrollTo(0, this.myRef.current.offsetTop)
  }

  onConfirm = () => {
    this.setState({
      isLoading: false,
      isLoading_modal: true
    });
  }
  onCancel = (e) => {
    this.setState({
      showAlert: false,
      offline: false
    });
  }

  handleChangeLogin = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const { errors } = this.validator;
    const { postlogin } = this.state;
    postlogin[name] = value;

    this.validator.validate(name, value)
      .then(() => {
        this.setState({ errors, postlogin })
      });
  }

  validateAndSubmitLogin = (e) => {
    e.preventDefault()

    const { postlogin } = this.state
    const { errors } = this.validator

    const valid = this.validator.validateAll(postlogin)

    if (valid) {
      this.handleLoginSubmit(postlogin)
    } else {
      this.setState({ errors })
    }
  }

  handleLoginSubmit = (postlogin) => {
    this.setState({ isLoading: true });
    navigator.onLine ?
      this.props.dispatch(AuthService.login(postlogin))
        .then((result) => {
          this.props.dispatch(navCartStore({
            showPr: true,
            showCart: false,
            showCarteadhesion: false,
            showReceveur: false,
            showPaiement: false,
            realCard: false
          }))

          this.setState({
            showAlert: false,
            showPr: true,
            showCarteadhesion: false,
            showCart: false,
            showReceveur: false,
            showPaiement: false,
            isLoading: false,
            offline: false,
            isLoading_modal: true
          });
          // if (!this.props.optionsRPStore) {
          //   this.allPointRelais();
          // }

          this.getUserDonatorsLink(this.props.prodId_t);

          this.profile();
        })
        .catch((err) => {
          this.setState({ show_t: 'show_t' });
          this.setState({ isLoading: false });
        })
      :
      this.setState({ offline: true, isLoading: false })
  }

  responseGoogle = async (response) => {
    const profileObj = response.profileObj;
    const data = {
      email: profileObj.email,
      social_provider: 'google',
      social_id: profileObj.googleId,
      social_nickname: profileObj.name,
      social_token: response.accessToken,
      social_refresh_token: response.accessToken
    }

    await this.props.dispatch(AuthService.socialLogin(data))
      .then((result) => {
        // this.props.history.push("/");
        this.props.dispatch(navCartStore({
          showPr: true,
          showCart: false,
          showCarteadhesion: false,
          showReceveur: false,
          showPaiement: false,
          realCard: false
        }))

        this.setState({
          showAlert: false,
          showPr: true,
          showCarteadhesion: false,
          showCart: false,
          showReceveur: false,
          showPaiement: false,
          isLoading: false,
          offline: false
        });
        // if (!this.props.optionsRPStore) {
        //   this.allPointRelais();
        // }
        this.profile();
      })
      .catch((err) => {
        this.setState({ show_t: 'show_t' });
        this.setState({ isLoading: false });
      })
  }
  responseFacebook = (response) => {
    console.log(response)
  }
  onUserExistBool = () => {
    this.setState({
      userExistBool: !this.state.userExistBool,
      userNotExistBool: !this.state.userNotExistBool
    });
  }

  onUserNotExistBool = () => {
    this.setState({
      userNotExistBool: !this.state.userNotExistBool,
      userExistBool: !this.state.userExistBool
    });
  }

  onSendCart = () => {
    this.setState({
      sendCart: true,
      seacrchList: [],
      nameChoice: '',
      cartActiveBool: false
    });
    // this.props.dispatch(seacrchListAction({ seacrchListStore: [] }))
    this.getUserReceiverLink(this.props.prodId_t);
  }

  onNotSendCart = () => {
    this.setState({
      sendCart: false,
      seacrchList: [],
      nameChoice: '',
      // cartActiveBool: !this.state.cartActiveBool
      cartActiveBool: true
    });
    // this.props.dispatch(seacrchListAction({ seacrchListStore: [] }))
    this.getUserDonatorsLink(this.props.prodId_t);
  }

  // profile = () => {
  //   this.props.dispatch(UsersService.profile())
  //     .then((result) => {
  //       let data = result;
  //       this.setState({ user_id: data.id });
  //        this.props.dispatch(prodId_t({__id: data.id, __rid: data.real_id}));
  //     })
  //     .catch((e) => {
  //       console.log('errors ', e);
  //     });
  // }

  getUserReceiverLink = (idDatas) => {
    this.props.dispatch(UsersService.getUserReceiverLink(idDatas.__rid, 1))
      .then((result) => {
        let data = result;
        this.setState({ seacrchList: data });
        // this.props.dispatch(seacrchListAction({ seacrchListStore: data }))
        // this.getAllReceiverProfiles(data, idDatas.__id)
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  getUserDonatorsLink = (idDatas) => {
    this.props.dispatch(UsersService.getUserDonatorsLink(idDatas.__rid, 1))
      .then((result) => {
        let data = result;
        // this.getAllReceiverProfiles(data, idDatas.__rid)
      })
      .catch((err) => {
        if (err.notNetwork === true) {
          this.setState({ offline: true, isLoading: false })
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
  }

  getAllReceiverProfiles = (dataValue, real_id) => {
    this.props.dispatch(UsersService.getAllReceiverProfiles(real_id))
      .then((result) => {
        let data = result.data;
        let datas = [...data, ...dataValue]
        this.setState({ seacrchList: datas });
        this.props.dispatch(seacrchListAction({ seacrchListStore: datas }))
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  findTargetUser = () => {
    this.props.dispatch(UsersService.searchTargetUser(this.props.prodId_t.__rid))
      .then((result) => {
        let data = result.data;
        this.props.dispatch(seacrchListAction({ seacrchListStore: data }))
        this.setState({ seacrchList: data });
      })
      .catch((e) => {
        console.log('errors ', e);
      });
  }

  calculateTotal = () => {
    return this.props.cartValue.reduce((total, item) => total + (item.usedPrice * item.qty), 0);
  };

  handleAddressBeneficiary = (addr) => {
    this.setState({ addressOfBeneficiary: addr });
  }

  getShortContryNameOfBeneficiary = (coord) => {
    this.setState({ allGeocodeInformationOfBeneficiary: coord });
    this.setState({ addressOfBeneficiary: coord.adress });

    // const distanceBeneficiary = this.distanceBetweenBeneficiaryAndOurLocaltion(coord.userLat, coord.userLng, coord.adress);
    // const distanceBeneficiary = this.distanceBetweenBeneficiaryAndOurLocaltion(distance1, selectedAddress);
    // this.props.dispatch(beneficiaryLocation({ distanceBeneficiary: distanceBeneficiary }))
  }

  // Fonction pour convertir de degrés en radians
  toRadians = (degrees) => {
    return (degrees * Math.PI) / 180;
  }

  // Fonction pour calculer la distance en kilomètres entre deux coordonnées géographiques
  calculateDistance = (lat1, lon1, lat2, lon2) => {

    // // Exemple de coordonnées de référence (à remplacer par des valeurs réelles si nécessaire)
    // const referenceCoord = { lat: 4.0408857, lng: 9.7334968 }; // Paris, France

    // // Calcul de la distance en utilisant DistanceMatrixService
    // const service = new window.google.maps.DistanceMatrixService();
    // service.getDistanceMatrix(
    //   {
    //     origins: [{ lat2, lon2 }],
    //     destinations: [referenceCoord],
    //     travelMode: window.google.maps.TravelMode.DRIVING,
    //   },
    //   (response, status) => {
    //     if (status === 'OK') {
    //       const distance = response.rows[0].elements[0].distance.text;
    //       console.log(`Distance to reference point1: ${distance}`);
    //       // return distance;
    //     } else {
    //       console.error('DistanceMatrixService failed due to: ' + status);
    //     }
    //   }
    // );

    const R = 6371; // Rayon de la Terre en kilomètres
    const dLat = this.toRadians(lat2 - lat1);
    const dLon = this.toRadians(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRadians(lat1)) *
      Math.cos(this.toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
  }
  distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap = (distance, adress) => {
    const radius1 = 7; // Rayon en kilomètres
    const radius2 = 14; // Rayon en kilomètres
    const radius3 = 21; // Rayon en kilomètres
    const distanceRound = parseFloat(distance).toFixed(1)
    if (distanceRound <= radius1) {
      this.props.dispatch(beneficiaryLocation({
        distanceBeneficiary:
        {
          zone: 1,
          adresse: adress,
          distance: distanceRound,
          montant: 1.6,
          libelle: `Vous êtes à ${distanceRound}km de Ndogsimbi(carrefour matango)`,
          isAtHome: "Yes"
        }
      }
      ))

      // return {
      //   zone: 1,
      //   adresse: adress,
      //   distance: distanceRound,
      //   montant: 1.6,
      //   libelle: `Vous êtes à ${distanceRound}km de Ndogsimbi(carrefour matango)`,
      //   isAtHome: "Yes"
      // };
    }
    if (distanceRound > radius1 && distanceRound <= radius2) {
      // return distance <= radius;
      this.props.dispatch(beneficiaryLocation(
        {
          distanceBeneficiary: {
            zone: 2,
            adresse: adress,
            distance: distanceRound,
            montant: 3,
            libelle: `Vous êtes à ${distanceRound}km de Ndogsimbi(carrefour matango)`,
            isAtHome: "Yes"
          }
        }
      ))
      // return {
      //     zone: 2,
      //     adresse: adress,
      //     distance: distanceRound,
      //     montant: 3,
      //     libelle: `Vous êtes à ${distanceRound}km de Ndogsimbi(carrefour matango)`,
      //     isAtHome: "Yes"
      //   };
    }
    if (distanceRound > radius2 && distanceRound <= radius3) {
      // return distance <= radius;
      this.props.dispatch(beneficiaryLocation(
        {
          distanceBeneficiary: {
            zone: 3,
            adresse: adress,
            distance: distanceRound,
            montant: 4.6,
            libelle: `Vous êtes à ${distanceRound}km de Ndogsimbi(carrefour matango)`,
            isAtHome: "Yes"
          }
        }
      ))
      // return {
      //     zone: 3,
      //     adresse: adress,
      //     distance: distanceRound,
      //     montant: 4.6,
      //     libelle: `Vous êtes à ${distanceRound}km de Ndogsimbi(carrefour matango)`,
      //     isAtHome: "Yes"
      //   };
    }
  }
  distanceBetweenBeneficiaryAndOurLocaltion = (destinationLat, destinationLon, adress) => {
    const userLat = 4.0408857;
    const userLon = 9.7334968;
    const radius1 = 7; // Rayon en kilomètres
    const radius2 = 14; // Rayon en kilomètres
    const radius3 = 21; // Rayon en kilomètres
    // Vérifiez si la distance entre l'utilisateur et la destination est inférieure au rayon défini
    const distance = this.calculateDistance(userLat, userLon, destinationLat, destinationLon);
    // console.log(`Distance to reference point: ${distance}`);
    const distanceRound = parseFloat(distance.toFixed(1))
    if (distanceRound <= radius1) {
      return {
        zone: 1,
        adresse: adress,
        distance: distanceRound,
        montant: 1.6,
        libelle: `Vous êtes à ${distanceRound}km de Ndogsimbi(carrefour matango)`,
        isAtHome: "Yes"
      };
    }
    if (distanceRound > radius1 && distanceRound <= radius2) {
      // return distance <= radius;
      return {
        zone: 2,
        adresse: adress,
        distance: distanceRound,
        montant: 3,
        libelle: `Vous êtes à ${distanceRound}km de Ndogsimbi(carrefour matango)`,
        isAtHome: "Yes"
      };
    }
    if (distanceRound > radius2 && distanceRound <= radius3) {
      // return distance <= radius;
      return {
        zone: 3,
        adresse: adress,
        distance: distanceRound,
        montant: 4.6,
        libelle: `Vous êtes à ${distanceRound}km de Ndogsimbi(carrefour matango)`,
        isAtHome: "Yes"
      };
    }
  }

  onHandleParentStateTotal = (htArrondie, tauxInteretArrondie, ttcArrondie) => {
    // let str = tauxInteretArrondie;

    this.setState({
      totalHtArrondie: htArrondie,
      totalTauxInteretArrondie: tauxInteretArrondie,
      totalTtcArrondie: ttcArrondie
    })

    this.props.dispatch(economicAccessibility({
      treatment_costs: this.props.treatment_costsStore,
      social_assistance: this.props.isSocialAssistanceOptionStore ? parseFloat(this.props.social_assistanceStore) : 3,
      delivery: this.props.deliveryStore,
      interest_rate: tauxInteretArrondie,
      fromCMD: this.props.fromCMDStore
    }));


    // this.props.dispatch(economicAccessibility({ 
    //   treatment_costs: data.command.data.treatment_costs,
    //   social_assistance: data.command.data.social_assistance,
    //   delivery: data.command.data.delivery,
    //   interest_rate: parseFloat( data.command.data.treatment_costs) !== 0 ? parseFloat(parseFloat(amountCMD) - (parseFloat(productObject.price) + parseFloat(data.command.data.treatment_costs) + parseFloat(data.command.data.social_assistance) + parseFloat(data.command.data.delivery))).toFixed(2) : 0,
    //   amountCMD,
    //   fromCMD: true,
    //   // totalHtArrondie: productObject.price + treatment_costs + social_assistance + delivery
    //   totalHtArrondie: parseFloat(productObject.price) + parseFloat(data.command.data.treatment_costs) + parseFloat(data.command.data.social_assistance) + parseFloat(data.command.data.delivery)
    // }));

  }

  insertInEconomicAccessibility = (val1, val2, val3, val4, val5, val6, val7) => {
    this.props.dispatch(economicAccessibility({
      treatment_costs: val1,
      social_assistance: val2,
      delivery: val3,
      interest_rate: val4,
      amountCMD: val5,
      fromCMD: val6,
      totalHtArrondie: val7
    }));

  }

  render() {
    const bytes = CryptoJS.AES.decrypt(this.props.def_theme, 'key-moimonnom@321');
    let d_theme = bytes.toString(CryptoJS.enc.Utf8);

    const { t } = this.props;
    let { errors } = this.state;

    return (
      <ThemeProvider theme={d_theme === "\"dark\"" ? darkTheme : lightTheme}>
        <>
          <GlobalStyles />
          <Helmet>
            <script src="../../../assets/js/core/popper.min.js" type="text/javascript" />
            <script src="../../../assets/js/core/bootstrap.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/chart.js/chart.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/datatables/datatables.min.js" type="text/javascript" />

            <script src="../../../assets/js/plugin/bootstrap-notify/bootstrap-notify.min.js" type="text/javascript" />
            <script src="../../../assets/js/plugin/sweetalert/sweetalert.min.js" type="text/javascript" />

            <script src="../../../assets/js/atlantis.min.js" type="text/javascript" />

            <script src="../../../assets/js/setting-demo.js" type="text/javascript" />
            <script src="../../../assets/js/demo.js" type="text/javascript" />
          </Helmet>


          <NewHeader
            option_categorie={this.state.option_categorie}
            allCategories={this.state.allCategories}
            fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
            cartValue={this.props.showCartValue && this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
            idProf={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
            gotToParentValue={this.searchCityWithCallback}
            isShowSearch_t={false}
            searchProducts_t={this.state.searchProducts_t}
            searchNameWithCallback={this.searchNameWithCallback}
            onLogout={this.onLogout}
            onToggle_t={this.onToggle_t}

            propsCustom={this.props}
            allProductsBycategories={this.allProductsBycategories}
            includesForAllProducts_t={this.state.includesForAllProducts_t}
          />

          <div id="side-bar"
            className={this.state.toggleBool_t ? "side-bar header-two show" : "side-bar header-two"}
          >
            <button className="close-icon-menu"
              onClick={this.onToggle_t}>
              <IconButton aria-label="close">
                <CloseIcon />
              </IconButton>
            </button>
            <MobileCategoriesMenu
              prodId_t={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
              onLogout={this.onLogout}
              allCategories={this.state.allCategories}
              isShowSearch_t={false}
              fName={this.props.prodId_t !== "" ? this.props.prodId_t.__pfn : ''}
            />

          </div>

          <div className=" register_t panier_t">
            {/* <div className="showAndHiddenResp_t">

              <HeaderUsersMobile
                cartValue={this.props.cartValue}
                isAuthResponsive={false}
                langBool={this.state.langBool}
                for_remove_js={this.for_remove_js}
                checked_switch={this.state.checked_switch}
                theme_botton_D={this.theme_botton_D}
                theme_botton_W={this.theme_botton_W}
                checked_t={this.checked_t}
                option_city={this.state.option_city}
                city_id={this.state.city_id}
                gotToParentValue={this.gotToParentValue}
                option_country={this.state.option_country}
                country_value={this.state.country_value}
                toggleBool_t={this.state.toggleBool_t}
                onToggle_t={this.onToggle_t}
                isAuth_t={this.props.isAuthenticated}
                onLogout={this.onLogout}
                isLoadingHeader={this.state.isLoadingHeader}
                idProf={this.props.prodId_t !== "" ? this.props.prodId_t.__id : ''}
                showCountry={false}
              />

            </div> */}

            <PanierContent
              categorie_value={this.state.categorie_value}
              option_categorie={this.state.option_categorie}
              gotToParentCatValue={this.gotToParentCatValue}
              gotToParentValue={this.gotToParentValue}
              categorieLabel={this.state.categorieLabel}
              parent_categorieLabel={this.parent_categorieLabel}
              parent_add_categorie={this.parent_add_categorie}
              onPrice_t={this.onPrice_t}
              onDropdownBtn_t={this.onDropdownBtn_t}
              dropdownBtn_t={this.state.dropdownBtn_t}
              onChange_t={(e) => this.onChange_t(e)}
              required={this.props.required}
              greater_than={this.state.greater_than}
              less_than={this.state.less_than}
              equal_t={this.state.equal_t}
              interval_t={this.state.interval_t}
              input2_bool_t={this.state.input2_bool_t}
              input1_bool_t={this.state.input1_bool_t}
              show_val_interv={this.state.show_val_interv}
              prix_t={this.state.prix_t}
              devise_t={this.state.devise_t}
              prix2_t={this.state.prix2_t}
              interval_error={this.state.interval_error}
              pagination={{
                enabled: true,
                type: "long" // long, short
              }}
              pageLength={this.state.count}
              total_pages={this.state.total_pages}
              current_page={this.state.current_page}
              data={this.state.products}
              gotToParentShowInput={this.gotToParentShowInput}
              onAddProduct={this.onAddProduct}
              index_t={this.state.index_t}
              products={this.state.products}
              bool_val={this.state.bool_val}
              textTriePrix={this.state.textTriePrix}
              showTrieBool_t={this.state.showTrieBool_t}
              height={'48%'}
              width={'48%'}
              heightResponsive={'38%'}
              widthResponsive={'38%'}
              cartValue={this.props.showCartValue.length > 0 ? this.props.showCartValue : this.props.cartValue}
              cartValueForCmd={this.props.cartValue}
              qtyValue_t={this.state.qtyValue_t}
              qtyValueArr_t={this.state.qtyValueArr_t}
              coefAndPrice={this.state.coefAndPrice}
              onQty_t={this.onQty_t}
              goToParentCoefAndPrice={this.goToParentCoefAndPrice}
              qty_t={this.state.qty_t}
              pointRelaisValue={this.state.pointRelaisValue}
              // optionPointRelais_t={this.state.optionPointRelais_t}
              optionPointRelais_t={!!this.props.optionsRPStore ? this.props.optionsRPStore : []}
              showPr={this.props.showPr}
              showCarteadhesion={this.props.showCarteadhesion}
              showCart={this.props.showCart}
              showReceveur={this.props.showReceveur}
              showPaiement={this.props.showPaiement}
              onShowPointRelais_t={this.onShowPointRelais_t}
              onShowPointRelaisFromPDiff_t={this.onShowPointRelaisFromPDiff_t}
              membershipCardClick={this.state.membershipCardClick}
              onChooseSocialAssistance={this.onChooseSocialAssistance}
              typeOfDeferredPayment={this.state.typeOfDeferredPayment}
              onshowCarteadhesion_t={this.onshowCarteadhesion_t}
              onNotshowCarteadhesion_t={this.onNotshowCarteadhesion_t}
              showCarteadhesionGS={this.props.showCarteadhesionGS}
              onMembershipCardClick={this.onMembershipCardClick}
              onReturnRelaisPoint_t={this.onReturnRelaisPoint_t}
              onShowReceveur_t={this.onShowReceveur_t}
              rpointIsNotchoosed={this.state.rpointIsNotchoosed}
              onShowPayment_t={this.onShowPayment_t}
              onShowReceveurRelais_t={this.onShowReceveurRelais_t}
              onReturnCart_t={this.onReturnCart_t}
              onReturnShop_t={this.onReturnShop_t}
              onMembershipCard_t={this.onMembershipCard_t}
              postregister={this.state.postregister}
              handleChange={this.handleChange}
              handleChangeDistrict={this.handleChangeDistrict}
              errors={errors}
              option_sexe={this.state.option_sexe}
              option_country={this.state.option_country}
              option_city={this.state.option_city}
              eye_password_bool={this.state.eye_password_bool}
              toggle={this.state.toggle}
              isLoading={this.state.isLoading}
              isValue={this.state.isValue}
              email_exit={this.state.email_exit}
              email_not_val={this.state.email_not_val}
              searchR_t={this.state.searchR_t}
              // seacrchList={this.state.seacrchList}
              seacrchList={!!this.props.seacrchListStore ? this.props.seacrchListStore : []}
              onUserDetails={this.onUserDetails}
              nameChoice={this.state.nameChoice}
              nameChoiceStore={this.props.nameChoiceStore}
              myRef={this.myRef}
              firstname={this.state.firstname}
              lastname={this.state.lastname}
              country={this.state.country}
              phone={this.state.phone}
              onDeleteProduct={this.onDeleteProduct}
              onsubmitExitingUsers_t={this.onsubmitExitingUsers_t}
              onsubmitNewUser_t={this.onsubmitNewUser_t}
              showBtnTrue2={this.state.showBtnTrue2}
              showBtnTrue={this.state.showBtnTrue}
              showAlert={this.state.showAlert}
              onSendCart={this.onSendCart}
              onNotSendCart={this.onNotSendCart}
              sendCart={this.state.sendCart}
              userExistBool={this.state.userExistBool}
              userNotExistBool={this.state.userNotExistBool}
              onUserExistBool={this.onUserExistBool}
              onUserNotExistBool={this.onUserNotExistBool}
              onPayment={this.onPayment}
              creator_name={this.state.creator_name}
              offline={this.state.offline}
              cartActiveBool={this.state.cartActiveBool}
              relaypointId={this.props.relaypointId}
              realCard={this.props.realCard}
              handleChangeSocialOption={this.handleChangeSocialOption}
              // selectedValue={this.state.selectedValue}
              selectedValue={this.props.selectedValueStore}
              // isSocialAssistanceOption={this.state.isSocialAssistanceOption}
              isSocialAssistanceOption={this.props.isSocialAssistanceOptionStore}
              isDeferredPayment={this.state.isDeferredPayment}
              postregisterStore={this.props.postregisterStore}
              totalSum={this.calculateTotal()}
              clickOnCmdStore={this.props.clickOnCmdStore}
              onHandleRelayPointId={this.onHandleRelayPointId}
              handleAddressBeneficiary={this.handleAddressBeneficiary}
              getShortContryNameOfBeneficiary={this.getShortContryNameOfBeneficiary}
              distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap={this.distanceBetweenBeneficiaryAndOurLocaltionViaGoogleMap}
              addressOfBeneficiary={this.state.addressOfBeneficiary}
              heure={this.state.heure}
              minute={this.state.minute}
              seconde={this.state.seconde}
              distanceBeneficiary={this.props.distanceBeneficiarySTore}
              onAtHomeChoose={this.onAtHomeChoose}
              // montantLivraison={this.state.montantLivraison}
              montantLivraison={this.props.montantLivraisonSotre}
              onHandleParentStateTotal={this.onHandleParentStateTotal}
              // totalHtArrondie={this.props.fromCMDStore === true ? this.props.totalHtArrondieStore : this.state.totalHtArrondie}
              totalHtArrondie={this.state.totalHtArrondie}
              totalTauxInteretArrondie={this.state.totalTauxInteretArrondie}
              // totalTtcArrondie={this.props.fromCMDStore === true ? this.props.amountStore : this.state.totalTtcArrondie}
              totalTtcArrondie={this.state.totalTtcArrondie}
              typeOfHLocatization={this.props.typeOfHLocatizationStore}
              paymentType={this.props.paymentTypeStore}
              isSocialPayment={this.props.isSocialPaymentStore}

              treatment_costs={parseFloat(this.props.treatment_costsStore)}
              social_assistance={parseFloat(this.props.social_assistanceStore)}
              delivery={parseFloat(this.props.deliveryStore)}
              amountS={parseFloat(this.props.amountStore)}
              fromCMD={this.props.fromCMDStore}
              totalHtArrondieS={parseFloat(this.props.totalHtArrondieStore)}
              interest_rate={parseFloat(this.props.interest_rateStore)}
              insertInEconomicAccessibility={this.insertInEconomicAccessibility}
              historyFromHome={this.props.history}
              creatorCreateUserProfile={this.creatorCreateUserProfile}
            />

            <DataModal
              modalAlertTitle={this.state.modalAlertTitle}
              onConfirm={this.onConfirm}
              onCancel={this.onCancel}
              showAlert={this.state.showAlert}
              errors={this.state.errors}
              postlogin={this.state.postlogin}
              eye_password_bool={this.state.eye_password_bool}
              handleChange={this.handleChangeLogin}
              validateAndSubmit={this.validateAndSubmitLogin}
              offline={this.state.offline}
              isLoading={this.state.isLoading}
              toggle={this.toggle}
              responseGoogle={this.responseGoogle}
              sign_in={'sign_in2'}
              height={'20px'}
              width={'20px'}
            />

          </div>
        </>
      </ThemeProvider>
    );
  };
}
export default withTranslation()(Page);