import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useHistory } from 'react-router-dom';
// import base_url from '../../../baseUrl';

const DropdownCategories = (props) => {
    const [open, setOpen] = useState(false);
    const [openCategories, setOpenCategories] = useState({});
    const [openSubcategories, setOpenSubcategories] = useState({});
    const history = useHistory();

    const toggleDrawer = (isOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(isOpen);
    };

    const closeDrawer = () => {
        setOpen(false);
    };

    const handleToggleCategory = (index) => {
        setOpenCategories((prevOpenCategories) => ({
            ...prevOpenCategories,
            [index]: !prevOpenCategories[index]
        }));
    };

    const handleToggleSubcategory = (categoryIndex, subcategoryIndex) => {
        setOpenSubcategories((prevOpenSubcategories) => ({
            ...prevOpenSubcategories,
            [`${categoryIndex}-${subcategoryIndex}`]: !prevOpenSubcategories[`${categoryIndex}-${subcategoryIndex}`]
        }));
    };

    const handleNavigation = (path) => {
        if (path) {
            history.push(path);
            props.isShowSearch_t ?
                props.allProductsBycategories(path + "?include=shop.provider.user.country,annexesImages")
                :
                console.log('');

            closeDrawer();
        }
    };

    const getIndentation = (level) => {
        return level * 32; // Adjust the multiplier to control the indentation
    };

    const renderSubcategories = (subcategories, categoryIndex, level) => (
        subcategories.map((sub, index) => (
            <React.Fragment key={index}>
                <ListItem
                    button
                    onClick={() => sub.subcategories.length > 0 ? handleToggleSubcategory(categoryIndex, index) : handleNavigation("/categories" + sub.path)}
                    style={{ paddingLeft: getIndentation(level) }}
                >
                    <ListItemIcon style={{ minWidth: 20 }}>
                        <img src={sub.iconUrl} alt={sub.name} style={{ height: '30px', maxWidth: '30px', marginRight: '10px' }} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography style={{ fontSize: 18 }}>{sub.name}</Typography>}
                    />
                    {sub.subcategories.length > 0 ? (openSubcategories[`${categoryIndex}-${index}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />) : null}
                </ListItem>
                {sub.subcategories.length > 0 && (
                    <Collapse in={openSubcategories[`${categoryIndex}-${index}`]} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {renderSubcategories(sub.subcategories, categoryIndex, level + 1)}
                        </List>
                    </Collapse>
                )}
            </React.Fragment>
        ))
    );

    return (
        <div>
            <IconButton onClick={toggleDrawer(true)}
                style={{
                    backgroundColor: '#629d23',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '8px 0px',
                    borderRadius: '5px',
                }}
            >
                <span>
                    <MenuIcon style={{ marginRight: 18, fontSize: '25px' }} />
                    All Categories
                </span>
                <KeyboardArrowDownIcon style={{ marginLeft: 'auto', fontSize: '30px' }} />
            </IconButton>

            <Drawer
                anchor="left"
                open={open}
                onClose={toggleDrawer(false)}
                sx={{ width: 350, flexShrink: 0 }}
                PaperProps={{ style: { width: 350, backgroundColor: '#f0f0f0' } }}
            >
                <List>
                    {/* <span>Nos Rayons</span> */}
                    <div className='firstSectionOfSwipeableBtn_t'>
                        &nbsp;
                        <span className='titleOffirstSection_t'>
                            Nos catégories
                        </span>
                        <span
                            className='closeSwipeableBtn_t'
                            onClick={toggleDrawer(false)}>
                            Fermer
                        </span>
                    </div>

                    {props.allCategories.map((category, index) => (
                        <React.Fragment key={index}>
                            <ListItem
                                button
                                onClick={() => category.subcategories.length > 0 ? handleToggleCategory(index) : handleNavigation("/categories" + category.path)}
                            >
                                <ListItemIcon style={{ minWidth: 20 }}>
                                    <img src={category.iconUrl} alt={category.name} style={{ height: '40px', maxWidth: '30px', marginRight: '10px' }} />
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography style={{ fontSize: 18 }}>{category.name}</Typography>}
                                />
                                {category.subcategories.length > 0 ? (openCategories[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />) : null}
                            </ListItem>
                            {category.subcategories.length > 0 && (
                                <Collapse in={openCategories[index]} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {renderSubcategories(category.subcategories, index, 1)}
                                    </List>
                                </Collapse>
                            )}
                        </React.Fragment>
                    ))}
                </List>
            </Drawer>
        </div>
    );
};

export default DropdownCategories;
