import React from 'react';
import { withTranslation } from 'react-i18next';

const SearchInputForModal = (props) => {


  return (
    <div className="col col-xs-5 search-bar w-search searchInputForModalClass_t " style={props.style_search_div}>
      <form action="#" className="search-header">
        <input type="text" placeholder="Search for products, categories"
          onClick={props.checkIfModalIsOpenOrNot}
          required />
        <a href="javascript: void(0);"
          onClick={props.checkIfModalIsOpenOrNot}
          className="rts-btn btn-primary radious-sm with-icon">
          <div className="btn-text">
            Search
          </div>
          <div className="arrow-icon">
            <i className="fa-light fa-magnifying-glass" />
          </div>
          <div className="arrow-icon">
            <i className="fa-light fa-magnifying-glass" />
          </div>
        </a>
      </form>


      {/* <div className={props.className_div_2}>
        <input
          className={props.input_className}
          id={props.input_id}
          placeholder={props.input_placeholder}
          type={props.input_type}
          name={props.name}
          onClick={props.checkIfModalIsOpenOrNot}
        />
        <button type="submit">
          <img
            src={require("../../../images/Search.png")}
            alt="Search"
            className={props.images_className} />

        </button>
      </div> */}
    </div>
  );
}
export default SearchInputForModal;