import React, { Component } from 'react';
import { Link, Route, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import DropdownCategories from './HeaderComponents/DropdownCategories';
import PersonIcon from '@mui/icons-material/Person';
import CartButton from '../components/CartButton';
import Badge from '@mui/material/Badge';
import AccountMenu from '../components/Header/AccountMenu';
import SearchWithAutoComplete from '../components/FormsComponent/SearchWithAutoComplete';
// import '../components/cssComponents/headerCss.css';

class NewHeader5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
        }
    }
    render() {
        const {
            option_categorie,
            allCategories,
            cartValue,
            fName,
            searchR_t,
            gotToParentValue,
            propsCustom,
            allProductsBycategories,
            includesForAllProducts_t
        } = this.props;

        return (
            <div>
                {/* <Helmet>
                    <link rel="stylesheet preload" href="assets/css/style.css" as="style" />
                </Helmet> */}
                <div className="rts-header-one-area-one">
                    <div className="header-top-area bg_primary">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="bwtween-area-header-top header-top-style-four">
                                        <div className="hader-top-menu">
                                            <a href="javascript:;">A propos de nous</a>
                                            {
                                                this.props.fName
                                                    ?
                                                    ''
                                                    :
                                                    <Link to="/login">
                                                        Mon Compte
                                                    </Link>
                                            }
                                            {/* <a href="#">Order Tracking</a> */}
                                        </div>
                                        {/* <p>Bienvenue sur to our Organic store&nbsp;EkoMart!</p> */}
                                        <div className="follow-us-social">
                                            <span>Suivez-nous sur:</span>
                                            <div className="social">

                                                <a href="#">
                                                    <i className="fa-brands">
                                                        <FacebookIcon
                                                            sx={{ fontSize: 25, color: '#3b5998', margin: '0 1px' }}
                                                        // fontSize="small"
                                                        />
                                                    </i>
                                                </a>

                                                <a href="javascript:;">
                                                    <img
                                                        src={require("../../images/whatsAppFooter.png")}
                                                        alt="Whatsapp"
                                                        style={{ fontSize: 24, margin: '0 1px' }}
                                                        className="imageRSFooter" />
                                                </a>

                                                <a href="#">
                                                    <i className="fa-brands">
                                                        <InstagramIcon
                                                            sx={{ fontSize: 24, color: '#E1306C', margin: '0 1px' }}
                                                        // fontSize="small"
                                                        />
                                                    </i>
                                                </a>



                                                {/* <a href="#"><i className="fa-brands fa-youtube" /></a>
                                            <a href="#"><i className="fa-regular fa-basketball" /></a>
                                            <a href="#"><i className="fa-brands fa-skype" /></a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="search-header-area-main-1">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="search-header-area-main bg_green_header without-category">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="logo-search-category-wrapper style-five-call-us">
                                                        <a href="/" className="logo-area">

                                                            <img
                                                                className="logo"
                                                                src={require("../../images/logoheader.png")}
                                                                alt="navbar brand"
                                                            />

                                                        </a>
                                                        <div className="category-search-wrapper style-five">
                                                            {/* <form action="#" className="search-header">
                                                                <input type="text" placeholder="Search for products, categories" required />
                                                                <a href="javascript: void(0);" className="rts-btn btn-primary radious-sm with-icon">
                                                                    <div className="btn-text">
                                                                        Search
                                                                    </div>
                                                                    <div className="arrow-icon">
                                                                        <i className="fa-light fa-magnifying-glass" />
                                                                    </div>
                                                                    <div className="arrow-icon">
                                                                        <i className="fa-light fa-magnifying-glass" />
                                                                    </div>
                                                                </a>
                                                            </form> */}


                                                            {this.props.isShowSearch_t ?
                                                                <SearchWithAutoComplete
                                                                    options={this.props.searchProducts_t}
                                                                    searchNameWithCallback={this.props.searchNameWithCallback}


                                                                    className_div_2={'form-group with-button search-input is-empty'}
                                                                    input_className={'form-control searchFontFamily_t'}
                                                                    input_id={'headerSearch'}
                                                                    input_placeholder={'Recherche sur wemaza'}
                                                                    input_type={'search'}
                                                                    name={'searchR_t'}
                                                                    images_className={'search_t'}
                                                                    gotToParentValue={gotToParentValue}
                                                                    // searchR_t={searchR_t}
                                                                    propsCustom={propsCustom}
                                                                    allProductsBycategories={allProductsBycategories}
                                                                    includesForAllProducts_t={includesForAllProducts_t}
                                                                />
                                                                :
                                                                ""
                                                            }

                                                        </div>
                                                        <div className="accont-wishlist-cart-area-header">
                                                            {
                                                                this.props.fName
                                                                    ?
                                                                    <AccountMenu
                                                                        fName={this.props.fName}
                                                                        idProf={this.props.idProf}
                                                                        onLogout={this.props.onLogout}
                                                                    />
                                                                    :
                                                                    <Link to="/login" className="btn-border-only account">
                                                                        <PersonIcon sx={{ color: '#2C3C28', fontSize: 20 }} />
                                                                        Mon Compte
                                                                    </Link>
                                                            }

                                                            {/* <a href="wishlist.html" className="btn-border-only wishlist">
                                                            <i className="fa-regular fa-heart" />
                                                            Wishlist
                                                        </a> */}


                                                            <div className="btn-border-only cart category-hover-header">
                                                                {/* <i className="fa-sharp fa-regular fa-cart-shopping" /> */}
                                                                <Badge badgeContent={cartValue.length > 0 ? parseInt(cartValue.length) : 0}
                                                                    anchorOrigin={{
                                                                        vertical: 'top',
                                                                        horizontal: 'left',
                                                                    }}
                                                                    sx={{
                                                                        '& .MuiBadge-badge': {
                                                                            backgroundColor: '#629d23',
                                                                            color: '#fff',
                                                                            fontSize: 12,
                                                                        },
                                                                    }}
                                                                >
                                                                    <span>Mon Panier</span>
                                                                </Badge>

                                                                {/* <div className="category-sub-menu card-number-show">
                                                                    <h5 className="shopping-cart-number">Shopping Cart (03)</h5>
                                                                    <div className="cart-item-1 border-top">
                                                                        <div className="img-name">
                                                                            <div className="thumbanil">
                                                                                <img src="assets/images/shop/cart-1.png" alt />
                                                                            </div>
                                                                            <div className="details">
                                                                                <a href="shop-details.html">
                                                                                    <h5 className="title">Foster Farms Breast Nuggets Shaped Chicken</h5>
                                                                                </a>
                                                                                <div className="number">
                                                                                    1 <i className="fa-regular fa-x" />
                                                                                    <span>$36.00</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="close-c1">
                                                                            <i className="fa-regular fa-x" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="cart-item-1">
                                                                        <div className="img-name">
                                                                            <div className="thumbanil">
                                                                                <img src="assets/images/shop/05.png" alt />
                                                                            </div>
                                                                            <div className="details">
                                                                                <a href="shop-details.html">
                                                                                    <h5 className="title">Foster Farms Breast Nuggets Shaped Chicken</h5>
                                                                                </a>
                                                                                <div className="number">
                                                                                    1 <i className="fa-regular fa-x" />
                                                                                    <span>$36.00</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="close-c1">
                                                                            <i className="fa-regular fa-x" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="cart-item-1">
                                                                        <div className="img-name">
                                                                            <div className="thumbanil">
                                                                                <img src="assets/images/shop/04.png" alt />
                                                                            </div>
                                                                            <div className="details">
                                                                                <a href="shop-details.html">
                                                                                    <h5 className="title">Foster Farms Breast Nuggets Shaped Chicken</h5>
                                                                                </a>
                                                                                <div className="number">
                                                                                    1 <i className="fa-regular fa-x" />
                                                                                    <span>$36.00</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="close-c1">
                                                                            <i className="fa-regular fa-x" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="sub-total-cart-balance">
                                                                        <div className="bottom-content-deals mt--10">
                                                                            <div className="top">
                                                                                <span>Sub Total:</span>
                                                                                <span className="number-c">$108.00</span>
                                                                            </div>
                                                                            <div className="single-progress-area-incard">
                                                                                <div className="progress">
                                                                                    <div className="progress-bar wow fadeInLeft" role="progressbar" style={{ width: '80%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                                                                                </div>
                                                                            </div>
                                                                            <p>Spend More <span>$125.00</span> to reach <span>Free Shipping</span></p>
                                                                        </div>
                                                                        <div className="button-wrapper d-flex align-items-center justify-content-between">
                                                                            <a href="cart.html" className="rts-btn btn-primary ">View Cart</a>
                                                                            <a href="checkout.html" className="rts-btn btn-primary border-only">CheckOut</a>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                <Link to="/panier" className="over_link" >

                                                                    <span style={{ color: '#FFF', 'font-weight': '700', 'font-size': '23px' }}>
                                                                        {/* {cartValue.length} */}
                                                                    </span>

                                                                </Link>

                                                                {/* <a href="cart.html" className="over_link" /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rts-header-nav-area-one header--sticky">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="nav-and-btn-wrapper">
                                        <div className="nav-area-bottom-left-header-four">
                                            <div className="category-btn category-hover-header five-style">

                                                <DropdownCategories
                                                    allCategories={allCategories}
                                                    allProductsBycategories={allProductsBycategories}
                                                    isShowSearch_t={this.props.isShowSearch_t} />

                                            </div>
                                        </div>
                                        <div className="right-btn-area header-five">
                                            <a href="#" className="btn-narrow">Trending Products</a>
                                            <button className="rts-btn btn-primary white-color-t">
                                                30% de réduction dès maintenant
                                                <span>Sale</span>
                                            </button>
                                        </div>
                                        {/* button-area end */}
                                    </div>
                                </div>
                                <div className="col-lg-12" style={{ background: '#629d23' }}>
                                    <div
                                        className="logo-search-category-wrapper after-md-device-header header-mid-five-call"
                                        style={{ background: '#629d23' }}>
                                        <Link to="/" className="logo-area">

                                            {/* <img
                                                className="logo"
                                                src={require("../../images/logoheader.png")}
                                                alt="navbar brand"
                                            /> */}

                                            <img
                                                className="navbar-brand"
                                                src={require("../../images/logo_header.png")}
                                                alt="navbar brand"
                                            />


                                            {/* <img src="assets/images/logo/logo-01.svg" alt="logo-main" className="logo" /> */}
                                        </Link>
                                        <div className="category-search-wrapper">
                                            <div className="category-btn category-hover-header">
                                                <img className="parent" src="assets/images/icons/bar-1.svg" alt="icons" />
                                                <span>Categories</span>
                                                <ul className="category-sub-menu">
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/01.svg" alt="icons" />
                                                            <span>Breakfast &amp; Dairy</span>
                                                            <i className="fa-regular fa-plus" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/02.svg" alt="icons" />
                                                            <span>Meats &amp; Seafood</span>
                                                            <i className="fa-regular fa-plus" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/03.svg" alt="icons" />
                                                            <span>Breads &amp; Bakery</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/04.svg" alt="icons" />
                                                            <span>Chips &amp; Snacks</span>
                                                            <i className="fa-regular fa-plus" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/05.svg" alt="icons" />
                                                            <span>Medical Healthcare</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/06.svg" alt="icons" />
                                                            <span>Breads &amp; Bakery</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/07.svg" alt="icons" />
                                                            <span>Biscuits &amp; Snacks</span>
                                                            <i className="fa-regular fa-plus" />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/08.svg" alt="icons" />
                                                            <span>Frozen Foods</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/09.svg" alt="icons" />
                                                            <span>Grocery &amp; Staples</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className="menu-item">
                                                            <img src="assets/images/icons/10.svg" alt="icons" />
                                                            <span>Other Items</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <form action="#" className="search-header">
                                                <input type="text" placeholder="Search for products, categories or brands" required />
                                                <button className="rts-btn btn-primary radious-sm with-icon">
                                                    <span className="btn-text">
                                                        Search
                                                    </span>
                                                    <span className="arrow-icon">
                                                        <i className="fa-light fa-magnifying-glass" />
                                                    </span>
                                                    <span className="arrow-icon">
                                                        <i className="fa-light fa-magnifying-glass" />
                                                    </span>
                                                </button>
                                            </form>
                                        </div>
                                        <div className="main-wrapper-action-2 d-flex">
                                            <div className="accont-wishlist-cart-area-header">
                                                <Link to="/login" className="btn-border-only account">
                                                    {/* <a href="account.html" className="btn-border-only account"> */}
                                                    <PersonIcon sx={{ color: '#2C3C28', fontSize: 20 }} />
                                                    {this.props.fName ? `${this.props.fName}` : "Mon Compte"}

                                                    {/* </a> */}
                                                </Link>
                                                {/* <a href="wishlist.html" className="btn-border-only wishlist">
                                                    <i className="fa-regular fa-heart" />
                                                    Wishlist
                                                </a> */}
                                                {/* <div className="btn-border-only cart category-hover-header"> */}
                                                <div className="cart category-hover-header"
                                                    style={{
                                                        color: '#fff',
                                                        'font-size': '14px',
                                                        'font-weight': 'bold'
                                                    }}>
                                                    <Badge badgeContent={cartValue.length > 0 ? parseInt(cartValue.length) : 0}
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        sx={{
                                                            '& .MuiBadge-badge': {
                                                                backgroundColor: '#8b4513',
                                                                color: '#fff',
                                                                fontSize: 12,
                                                            },
                                                        }}
                                                    >
                                                        <span>Mon Panier</span>
                                                    </Badge>
                                                    <Link to="/panier" className="over_link" >

                                                        <span style={{ color: '#FFF', 'font-weight': '700', 'font-size': '23px' }}>
                                                            {/* {cartValue.length} */}
                                                        </span>

                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="actions-area">
                                                {/* <div className="search-btn" id="search">
                                                    <svg width={17} height={16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M15.75 14.7188L11.5625 10.5312C12.4688 9.4375 12.9688 8.03125 12.9688 6.5C12.9688 2.9375 10.0312 0 6.46875 0C2.875 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.46875 13C7.96875 13 9.375 12.5 10.5 11.5938L14.6875 15.7812C14.8438 15.9375 15.0312 16 15.25 16C15.4375 16 15.625 15.9375 15.75 15.7812C16.0625 15.5 16.0625 15.0312 15.75 14.7188ZM1.5 6.5C1.5 3.75 3.71875 1.5 6.5 1.5C9.25 1.5 11.5 3.75 11.5 6.5C11.5 9.28125 9.25 11.5 6.5 11.5C3.71875 11.5 1.5 9.28125 1.5 6.5Z" fill="#1F1F25" />
                                                    </svg>
                                                </div> */}
                                                <div className="menu-btn" id="menu-btn"
                                                    onClick={this.props.onToggle_t}>
                                                    <svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect y={14} width={20} height={2} fill="#1F1F25" />
                                                        <rect y={7} width={20} height={2} fill="#1F1F25" />
                                                        <rect width={20} height={2} fill="#1F1F25" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewHeader5;
