import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import GoodDealProdComponent from './../../../components/GoodDealProdComponent';
import ProductComponent from './ProductComponent';
import Footer from './../../../layout/Footer';
import ProductComponentGoodDeal from './ProductComponentGoodDeal';
import '../../../../styleSheets/App.css';

class HomeComponent extends Component {

  render() {
    const {
      t,
      onAddProduct,
      index_t,
      products,
      isCart_t,
      hash_id,
      fromHomePage,
      isHovered,
      setIsHovered,
      indexHomeProd,
      detailPage
    } = this.props;

    return (
      <div className="container-fluid container_home_products_section HomePageFirstChild">
        {/* <div className="row marg_null_t"> */}
        {/* <div className="col col-xs-6 col-sm-4 col-md-3 col-lg-3 color_cat_t"
            style={{ margin: '20px 0 0 10px', padding: '5px', 'max-width': '250px' }}> */}
        {/* <div className="showAndHiddenRespDesk_t">
              <CatSelectComponent
                select_value={categorie_value}
                style_f_Select_div={{ margin: '5px 0 0 -5px', color: '#000' }}
                name={'cat_prod'}
                options={option_categorie}
                placeholder={categorieDefault_value !== '' ? categorieDefault_value : 'Catégorie'}
                gotToParentCatValue={gotToParentCatValue}
                bool_val={this.props.bool_val}
              />
            </div> */}
        {/* <div className="showAndHiddenResp_t">
              <CatSelectComponent
                select_value={categorie_value}
                style_f_Select_div={{ margin: '-5px 0 0 -5px', color: '#000', width: '200px' }}
                name={'cat_prod_mobil'}
                options={this.props.optionsResp}
                placeholder={categorieDefault_value !== '' ? categorieDefault_value : 'Catégorie'}
                gotToParentCatValue={gotToParentCatValue}
                bool_val={this.props.bool_val}
              />
            </div>

          </div> */}
        {/* <CatListComponent
            showResponsiveClass={"cat_list_t showAndHiddenRespDesk_t"}
            categorieLabel={categorieLabel}
            parent_categorieLabel={parent_categorieLabel}
            parent_add_categorie={parent_add_categorie}
          /> */}
        {/* </div> */}

        {/* <div className="mrgHC"></div> */}
        {/* <Carousel /> */}
        
        {/* <div className='container-fluid'>
          <div className='row'
            style={{ justifyContent: 'center', marginTop: '20px' }}>
            <div className='col-12 col-lg-7'>
              <CarouselHomePage />
            </div>

            <div className='col-12 col-lg-4 col-xl-4 col-xxl-3'>
              <a href="javascript:;">
                <img
                  className='smallBanner'
                  width="100%"
                  alt="Cagnotte S09"
                  src={bannerTo}
                />
              </a>
              <a href="javascript:;">
                <img
                  className='smallBanner'
                  style={{ marginTop: '10px' }}
                  width="100%"
                  alt="Cagnotte S09"
                  src={bannerToBottom}
                />
              </a>
            </div>
          </div>
        </div> */}

        {this.props.offline ?
          <span className="offlinePage_t">
            Verifiez votre connextion internet puis réesayez
          </span>
          : ''
        }

        <div className="row marg_null_t">
          <span className="new_product_t">
            Bons plans : jusqu'à - 50 %
          </span>
        </div>


        {/* <img
          src={require("../../../../images/camera.png")}
          alt="Camera_t"
          className="camera_t isResponsive_t" />
        <img
          src={require("../../../../images/jack.png")}
          alt="Jack"
          className="jack_t isResponsive_t" />
        <img
          src={require("../../../../images/casque.png")}
          alt="Casque"
          className="casque_t" />
        <img
          src={require("../../../../images/casque.png")}
          alt="Casque"
          className="casque2_t" />
        <img
          src={require("../../../../images/jack.png")}
          alt="Jack"
          className="jack2_t isResponsive_t" /> */}

        <div className="row marg_null_t justify_content_center_t">
          <ProductComponentGoodDeal
            onAddProduct={onAddProduct}
            index_t={index_t}
            products={this.props.data}
            isCart_t={isCart_t}
            hash_id={hash_id}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            indexHomeProd={indexHomeProd}
            detailPage={detailPage}
            gooddeal_bool={true}
          />
        </div>


        <div className="row marg_null_t">
          <span className="new_product_t">
            Nouveautés
          </span>
        </div>


        <div className="row marg_null_t justify_content_center_t">
          <ProductComponent
            onAddProduct={onAddProduct}
            index_t={index_t}
            products={products}
            isCart_t={isCart_t}
            hash_id={hash_id}
            isHovered={isHovered}
            setIsHovered={setIsHovered}
            indexHomeProd={indexHomeProd}
            detailPage={detailPage}
            gooddeal_bool={false}
          />
        </div>

        <div className="row marg_null_t">
          {/* <div className="col col-sm-6">
            <p className="slider_or_t"></p>
          </div>
          <div className="col col-sm-6">
            <p className="slider_or_t"></p>
          </div> */}
        </div>

        {/* <p className="paragr_m_deal_t"> */}
        <p className="new_product_t" style={{ margin: '20px .6rem 40px 1rem' }}>
          Ne dépensez pas beaucoup... Découvrez toutes les meilleurs ventes
        </p>

        <div className="row marg_null_gd_t">
          <GoodDealProdComponent
            onAddProduct={onAddProduct}
            index_t={index_t}
            products={products}
            isCart_t={isCart_t}
            hash_id={hash_id}
            fromHomePage={fromHomePage}
          />
        </div>
        
        {/* <span className="new_product_t" style={{
          display: 'block',
          margin: '30px 0 20px 15px'
        }}>
          Témoignage client
        </span>

        <CarouselTemoignage />

        <div className="row marg_footerTop_gd_t" >
          <FooterTop
            require={require("../../../../images/smile.png")}
            first_title={'Satisfait ou remboursé'}
            second_title={'Remise pour les membre'}
          />
          <FooterTop
            require={require("../../../../images/thumbs-up.png")}
            first_title={'Retours sans frais'}
            second_title={'Service Après Vente agrée'}
          />
          <FooterTop
            require={require("../../../../images/truck.png")}
            first_title={'Paiement 100% sécurisé'}
            second_title={'Nous sécurison vos paiements'}
          />
          <FooterTop
            require={require("../../../../images/truck.png")}
            first_title={'Assistance 24/7'}
            second_title={'Contactez-nous 24/24'}
          />
        </div> */}


        <div className="showAndHiddenRespDesk_t">
          <Footer />
        </div>
        <div className="footerMobil_t">
          &nbsp;&nbsp;
        </div>
      </div>
    );
  }
}
export default withTranslation()(HomeComponent);